import NextLink from 'next/link';

import { Divider } from 'components/ToolkitV2/Breadcrumbs/Components/Divider/Divider';

import * as Styled from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs.styled';

import type { BreadcrumbsProps } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs.typed';
import type { LinkType } from 'components/Toolkit/Button/SharedLink';

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { breadcrumbs, goBack, inverted, className } = props;

  const linkType: LinkType = inverted ? 'INVERTED' : 'SECONDARY';

  return (
    <Styled.Container className={className}>
      <Styled.BackButton onClick={goBack} ofType={linkType}>
        <Styled.BackIcon aria-hidden="true" />
        <Styled.BackText>Back</Styled.BackText>
      </Styled.BackButton>

      <Styled.BreadcrumbsOuterContainer>
        <Styled.BreadcrumbsInnerContainer>
          {breadcrumbs.map(
            (
              { displayName, href, isClientSide = true, ...linkProps },
              index,
            ) => {
              const displayDivider = breadcrumbs.length - 1 !== index;

              return (
                <Styled.BreadcrumbAndDividerContainer
                  key={`breadcrumb-${index}`}
                >
                  {!href ? (
                    <Styled.Breadcrumb>
                      <Styled.BreadcrumbText inverted={inverted}>
                        {displayName}
                      </Styled.BreadcrumbText>
                    </Styled.Breadcrumb>
                  ) : isClientSide ? (
                    <Styled.BreadcrumbLink
                      data-testid={`${displayName}-breadcrumb`}
                      NextLink={NextLink}
                      ofType={linkType}
                      href={href}
                      {...linkProps}
                    >
                      {displayName}
                    </Styled.BreadcrumbLink>
                  ) : (
                    <Styled.BreadcrumbLink
                      data-testid={`${displayName}-breadcrumb`}
                      isFakeLink={false}
                      ofType={linkType}
                      href={href}
                      {...linkProps}
                    >
                      {displayName}
                    </Styled.BreadcrumbLink>
                  )}
                  {displayDivider && <Divider inverted={inverted} />}
                </Styled.BreadcrumbAndDividerContainer>
              );
            },
          )}
        </Styled.BreadcrumbsInnerContainer>
      </Styled.BreadcrumbsOuterContainer>
    </Styled.Container>
  );
};

export { Breadcrumbs };
