import { useEffect } from 'react';
import styled from 'styled-components';
import StatusCode from 'status-code-enum';

import { media } from 'helpers/breakpoints';
import { pushToDataLayer } from 'services/gtmService';
import { ButtonLink } from 'components/Toolkit/Button/ButtonLink';
import { Link } from 'components/Toolkit/Button/Link';

/**
 * List of status codes for which there is a specific error page UI
 */
const SUPPORTED_STATUS_CODES: Array<StatusCode> = [
  StatusCode.ClientErrorNotFound,
];

type Data = {
  image: string;
  imageAlt: string;
  title: string;
  message: string;
};

interface ErrorPageProps {
  cdnUrl: string;
  status?: StatusCode | 'default';
  disableGtmLog?: boolean;
}

const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing.M16};
  padding-bottom: ${({ theme }) => theme.spacing.XL64};

  ${media.medium} {
    padding-top: ${({ theme }) => theme.spacing.M24};
    padding-bottom: ${({ theme }) => theme.spacing.XL80};
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.M16};
`;

const Image = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  margin-left: auto;
  margin-right: auto;

  ${media.medium} {
    width: 288px;
    height: 288px;
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const Title = styled.p`
  ${({ theme }) => theme.fontSize.H32};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  text-align: center;

  ${media.medium} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  text-align: center;

  ${media.medium} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const SButtonLink = styled(ButtonLink)`
  width: 288px;
`;

function ErrorPage({
  cdnUrl,
  status = 'default',
  disableGtmLog = false,
}: ErrorPageProps) {
  const statusCode =
    SUPPORTED_STATUS_CODES.find((code) => code === status) || 'default';
  useEffect(() => {
    if (statusCode !== StatusCode.ClientErrorNotFound && !disableGtmLog) {
      pushToDataLayer({ event: 'error' });
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const data: Record<string, Data> = {
    [StatusCode.ClientErrorNotFound]: {
      image: 'error-car.png',
      imageAlt: 'Black car with 404 number plate',
      title: 'Wrong Turn?',
      message:
        'If you typed an address into your browser, please check the spelling and try again.',
    },
    default: {
      image: 'something-went-wrong.svg',
      imageAlt: 'Image of a tyre, four-way wrench and a pair of hex nuts',
      title: 'Ooops',
      message: 'Sorry, it looks like something has gone wrong',
    },
  };
  return (
    <Container>
      <Contents>
        <Image
          src={`${cdnUrl}/images/illustrations/${data[statusCode].image}`}
          alt={data[statusCode].imageAlt}
          width="240"
          height="240"
        />
        <Title>{data[statusCode].title}</Title>
        <Subtitle>{data[statusCode].message}</Subtitle>
        {status === StatusCode.ClientErrorNotFound.toString() && (
          <Subtitle>
            Did you get here from a link on DoneDeal? <br />
            Please{' '}
            <Link
              href="https://hello.donedeal.ie/hc/en-us/requests/new"
              target="_blank"
              rel="noopener"
              ofType="SECONDARY"
              inline
              underline
            >
              let us know
            </Link>{' '}
            where you came from so we can fix it!
          </Subtitle>
        )}
        <SButtonLink href="/">Back to the homepage</SButtonLink>
      </Contents>
    </Container>
  );
}

export { ErrorPage };
