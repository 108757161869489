import { useState } from 'react';
import { useRouter } from 'next/router';

import type { PaginationEvent } from 'types';
import type {
  UseDealerDirectory,
  UseDealerDirectoryData,
  UseDealerDirectoryProps,
} from 'components/DealerDirectory/components/Dealers/Dealers.typed';
import { fireToast } from 'helpers/Toasts';
import { isOk } from 'domains/Result';
import { dealerRepository } from 'repositories/Dealer/DealerRepository';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { formatSEOContent } from 'components/DealerDirectory/helpers/index.format';
import {
  mapParametersToQuery,
  mapQueryToParameters,
  mapfilterNameToQuery,
  mapQueryToPayload,
} from 'components/DealerDirectory/helpers/index.mapper';
import * as constants from 'components/DealerDirectory/constants';

const useDealers = (props: UseDealerDirectoryProps): UseDealerDirectory => {
  const router = useRouter();
  const [data, setData] = useState<UseDealerDirectoryData>(props);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { seoContent, dealers, paging } = data;
  const query = mapParametersToQuery(router.query);

  const updateDealers = async () => {
    setIsLoading(true);
    const payload = mapQueryToPayload(query);
    const dealersResponse = await dealerRepository.search(payload);
    if (isOk(dealersResponse)) {
      const { dealers, count, paging } = dealersResponse.ok;
      const seoContent = formatSEOContent(count, query.area);
      setData({ seoContent, dealers, paging });
    } else fireToast({ type: 'ERROR', text: 'Oops! Something went wrong.' });
    setIsLoading(false);
  };

  const formatParameters = (parameters: URLSearchParams) => {
    if (parameters.toString() === '') return '';
    return `?${parameters.toString()}`;
  };

  const push = async (parameters: URLSearchParams) =>
    await router.push(formatParameters(parameters), undefined, {
      shallow: true,
      scroll: true,
    });

  const onPageChange = async (event: PaginationEvent) => {
    const queryKey = mapfilterNameToQuery('from');
    const value = event.from.toString();
    const parameters = mapQueryToParameters(query);
    const urlSearchParams = new URLSearchParams({ ...parameters });
    if (value && value !== '0') urlSearchParams.set(queryKey, value);
    else urlSearchParams.delete(queryKey);
    push(urlSearchParams);
  };

  const goto = async (page: 'start' | 'end') => {
    switch (page) {
      case 'end':
        await onPageChange({
          ...constants.DEFAULT_PAGINATION_EVENT,
          pageSize: paging.pageSize,
          from: paging.totalPages * paging.pageSize - paging.pageSize,
        });
        break;
      case 'start':
      default:
        await onPageChange({
          ...constants.DEFAULT_PAGINATION_EVENT,
          pageSize: paging.pageSize,
          from: 0,
        });
    }
  };

  const isPaging = paging.totalPages > 1;

  useOnUpdateOnly(() => {
    updateDealers();
  }, [router.query]);

  useOnUpdateOnly(() => {
    setIsLoading(false);
  }, [data]);

  return {
    seoContent,
    dealers,
    paging,
    isLoading,
    isPaging,
    isDealers: dealers.length > 0,
    onPageChange,
    goto,
  };
};

export { useDealers };
