import NextHead from 'next/head';
import { NextSeo } from 'next-seo';

import type { HeadProps } from 'components/DealerDirectory/components/Head/Head.typed';
import { CDN_STATIC_ASSETS } from 'utils';
import { useHead } from 'components/DealerDirectory/components/Head/Head.hook';

const imagesToPreload: Array<Array<string>> = [
  ['/images/hero/dealerDirectory/blurred/small.jpg', '(max-width: 39.9375em)'],
  [
    '/images/hero/dealerDirectory/blurred/medium.jpg',
    '(min-width: 40em) and (max-width: 63.9375em)',
  ],
  ['/images/hero/dealerDirectory/blurred/large.jpg', '(min-width: 64em)'],
];

const Head = (props: HeadProps) => {
  const { seo } = useHead(props);
  return (
    <>
      <NextSeo {...seo} />
      <NextHead>
        {imagesToPreload.map(([href, media], index) => {
          return (
            <link
              key={`hero-image-${index}`}
              rel="preload"
              href={`${CDN_STATIC_ASSETS}${href}`}
              as="image"
              media={media}
            />
          );
        })}
      </NextHead>
    </>
  );
};

export { Head };
