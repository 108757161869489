import type { NextSeoProps } from 'next-seo';

import type { DealerDirectorySEOContent } from 'components/DealerDirectory/DealerDirectory.typed';
import { capitalizeFirstLetter } from 'helpers/string';
import { formatNumber } from 'helpers/formatting';
import * as constants from 'components/DealerDirectory/constants';

/**
 * This function correctly formats the location for display
 * @param location an optional location (county)
 * @returns formatted location text
 */
export const formatLocation = (location?: string): string => {
  return location ? `Co. ${location}` : constants.DEFAULT_LOCATION_TEXT;
};

/**
 * This function should gather and format all SEO content required for the page
 * @param cdnUrl The CDN URL
 * @param location The selected location
 * @returns SEO meta content for the page
 */
export const formatSEOMeta = (
  cdnUrl: string,
  location?: string,
): NextSeoProps => ({
  title: `Find car dealerships in ${formatMetaTitle(location)} on DoneDeal`,
  description:
    'Browse a comprehensive directory of trusted car dealerships on DoneDeal. Find local dealers near you, read customer reviews, and connect with trusted sellers.',
  openGraph: {
    title: 'Find car dealerships in Ireland on DoneDeal',
    description:
      'Browse a comprehensive directory of trusted car dealerships on DoneDeal. Find local dealers near you, read customer reviews, and connect with trusted sellers. Start the journey to find the right car for you today!',
    images: [
      {
        url: `${cdnUrl}/images/meta/dealer-directory-og.png`,
        width: 1200,
        height: 630,
      },
    ],
  },
});

/**
 * This function should gather and format all SEO text content required for the page
 * @param count The count of dealers
 * @param location The selected location
 * @returns SEO text content for the page
 */
export const formatSEOContent = (
  count: number,
  location?: string,
): DealerDirectorySEOContent => ({
  countInLocationText: `${formatNumber(count)} dealerships in ${formatLocation(
    location,
  )}`,
});

const formatMetaTitle = (location?: string): string => {
  return location
    ? `${capitalizeFirstLetter(location)}`
    : constants.DEFAULT_META_TITLE_LOCATION;
};
