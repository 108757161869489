import type { CSSProperties } from 'styled-components';
import styled, { css } from 'styled-components';

import { media } from 'helpers/breakpoints';

const skeletonCSS = css`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(242, 242, 242, 0) 0,
      rgba(242, 242, 242, 0.2) 20%,
      rgba(242, 242, 242, 0.5) 60%,
      rgba(242, 242, 242, 0)
    );
    animation: waveEffect 2s infinite;
    content: '';

    @keyframes waveEffect {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

const formatProperty = (property: string | number) =>
  typeof property === 'string' ? property : `${property}px`;

export type SkeletonBlockProps = {
  w: CSSProperties['width'];
  h: CSSProperties['height'];
  wMedium?: CSSProperties['width'];
  hMedium?: CSSProperties['height'];
  wLarge?: CSSProperties['width'];
  hLarge?: CSSProperties['height'];
};
const SkeletonBlock = styled.div<SkeletonBlockProps>`
  ${({ w }) => w && `width: ${formatProperty(w)}`};
  ${({ h }) => h && `height: ${formatProperty(h)}`};

  ${media.medium} {
    ${({ wMedium }) => wMedium && `width: ${formatProperty(wMedium)};`}
    ${({ hMedium }) => hMedium && `height: ${formatProperty(hMedium)};`}
  }

  ${media.large} {
    ${({ wLarge }) => wLarge && `width: ${formatProperty(wLarge)};`}
    ${({ hLarge }) => hLarge && `height: ${formatProperty(hLarge)};`}
  }
  ${skeletonCSS};
`;

export { SkeletonBlock };
