import type { Currency } from 'types';

export interface ISection {
  name: string;
  displayName: string;
  id?: number; // TODO: Optional for now but should be required after Ad Details Panel refactor
  subdomain: string;
  containsSubsections: boolean;
  xtn2: number;
  parentName: string;
  showInPlaceAd: boolean;
  subSections?: ISection[];
}

export interface IProduct {
  id: string | number; //TODO: update
  display: string;
  name: string;
  price: number;
  sterlingPrice: number;
}

export interface IPreviewKeyInfo {
  name: string;
  displayName: string;
  value: string;
}

export interface IPublishFilters {
  badgeView: { showBadge: boolean };
  displayName: string;
  filterType: {
    id: number;
    name:
      | 'TextBox'
      | 'TextBoxNumeric'
      | 'ListSingleSelect'
      | 'DependantSingleSelectList'
      | 'DatePicker'
      | 'RadioButton'
      | 'TextBoxVehicleLookup'
      | 'UneditableTextBoxVehicleLookup'
      | 'TextBoxWithUnit'
      | 'GreenlightLookup';
  };
  id: number;
  infoTip?: string | null;
  maxLength?: number;
  minimizeByDefault: boolean;
  name: string;
  optional: boolean;
  placeHolder: string;
  toolTip?: string | null;
  validationError?: string | null;
  validationRegEx?: string | null;
  values?: {
    id: number;
    displayName: string;
    valueType: string;
    value: string;
  }[];
}

export interface IPublishFilter {
  id: number;
  optional?: boolean;
  filterType: IFilterTypeView;
  minimizeByDefault?: boolean;
  maxLength?: number;
  validationRegEx?: string;
  placeHolder?: string;
  validationError?: string;
  toolTip?: string;
  infoTip?: string;
  popularValues?: IFilterValueView[];
  badgeView?: IBadgeView;
  values?: IFilterValueView[];
  displayName: string;
  name: string;
}

export interface RetrieveSuggestedSectionResponse {
  id: number;
  name: string;
  display: string;
  topSectionName: string;
  subdomain: string;
  euroPriceCents: number;
  gbpPriceCents: number;
  free: boolean;
  xtn2: number;
  hasSubSections: boolean;
  hasLinks: boolean;
  placeAdEnabled: boolean;
}

export interface IEditAdResponse {
  dataTargetingProps: any;
  header: string;
  description: string;
  currency: Currency;
  price: string;
  county: string;
  publisherName: string;
  publisherPhone: string;
  emailResponse: boolean;
  phoneResponse: boolean;
  section: {
    name: string;
    displayName: string;
    id: number;
    title: string;
    subdomain: string;
    containsSubsections: boolean;
    xtn2: number;
  };
  attributes?: { name: string; value: string }[];
  countyTown: string;
  traderName: string | null;
  traderAddress: string | null;
  traderVatnumber: string | null;
  photos: IPhotoResponse[] | null;
  vehicleData: null | IVehicleData;
  acceptStripePayment: boolean;
  shippingAvailable: boolean;
  shippingFee: null;
  quantity: number;
  freeShipping: boolean;
  published: boolean;
  maxPhotoCount: number;
  video: string | null;
  adTypes: AdTypes[];
}

export interface IPhotoResponse {
  id: string;
  location: string;
}

export type TRotatedPhotoData = {
  serverId: string;
  url: string;
};

export interface IVehicleData {
  metaInfoBlock: {
    apiVersion: null;
    items: IVehicleDataItem[];
    extraInfo: { title: string; text: string };
  };
  hiddenFields: {
    apiVersion: null;
    items: IVehicleDataItem[];
    extraInfo: null;
  };
  showGreenlight: boolean;
  greenlightTip: {
    title: string;
    text: string;
  };
  lookupSuccessTip: {
    title: string;
    text: string;
    text2: string;
  };
}

interface IVehicleDataItem {
  name: string;
  displayName: string;
  value: string;
  displayValue: string;
  editable: boolean;
  values?: IVehicleDataItemValues[];
}

interface IVehicleDataItemValues {
  id: number;
  valueType: 'SearchFilterStringValue';
  value: string;
  displayName: string;
  name: string;
}

export interface IEditAdPublishFilters {
  id: number;
  displayName: string;
  name: string;
  publishFilters: IPublishFilters[];
}

export interface ISuggestedProduct {
  id: number;
  sterlingPrice: number;
  price: number;
  display: string;
  name: string;
  modal?: {
    icon: string;
    titleText: string;
    boldInfoText: string;
    infoText: string;
    acceptText: string;
    declineText: string;
    footer: {
      preText: string;
      mainText: string;
      linkText: string;
      linkUrl: string;
    };
  };
}

interface IFilterTypeView {
  id: number;
  name: string;
}

interface IFilterValueView {
  id: number;
  valueType: string;
  value: string;
  displayName: string;
  name: string;
}

interface IBadgeView {
  id?: number;
  displayName?: string;
  showBadge?: boolean;
}

export type TAdFormVariant = 'PUBLISH' | 'UPDATE';

export type SectionWithId = { section: string; id: number };

export enum AdTypesVariant {
  FOR_SALE = 'for-sale',
  WANTED = 'wanted',
  C2B = 'c2b',
}

export type AdTypes = 'for-sale' | 'wanted' | 'c2b';

export enum PriceTypes {
  ON_REQUEST = 'On request',
}
