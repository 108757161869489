import type { PaginationEvent } from 'types';

export const DEFAULT_LOCATION_TEXT = 'All Ireland';
export const DEFAULT_META_TITLE_LOCATION = 'Ireland';
export const DEFAULT_FRANCHISE_TEXT = 'All Makes';
export const DEFAULT_TERMS_TEXT = 'All Dealerships';
export const DEFAULT_RESULTS_AMOUNT = 10;
export const DEFAULT_PAGINATION_EVENT: Omit<
  PaginationEvent,
  'from' | 'pageSize'
> = {
  filterType: 'Pagination',
  searchQueryGroup: '',
};
