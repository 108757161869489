import styled, { css } from 'styled-components';
import { Pagination as BasePagination } from '@dsch/pagination';
import {
  ChevronBackOutlineIc,
  ChevronForwardOutlineIc,
  ChevronBackOutlineVariantIc,
  ChevronForwardOutlineVariantIc,
} from '@dsch/dd-icons';

import { GhostButton } from 'components/Toolkit/Button/GhostButton';

import { media } from 'helpers/breakpoints';
import type { Paging } from 'types';
import { formatNumber } from 'helpers/formatting';

export interface IPagination extends Paging {
  goToStart?: () => void;
  goToEnd?: () => void;
  className?: string;
}

interface INavigationLinkButton {
  includeMargin?: boolean;
}

const MarginLeftCSS = css`
  margin-left: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    margin-left: 0;
  }
`;

const MarginRightCSS = css`
  margin-right: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    margin-right: ${({ theme }) => theme.spacing.M24};
  }
`;

const SPagination = styled(BasePagination)`
  ${({ theme }) => theme.fontSize.M14}
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.GREY};
`;

const BookendGhostButtonContainer = styled.span`
  display: flex;
  align-items: center;

  ${media.large} {
    display: none;
  }
`;

const NavigationGhostButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const NavigationGhostButton = styled(GhostButton)<INavigationLinkButton>`
  ${({ includeMargin }) => (includeMargin ? MarginRightCSS : MarginLeftCSS)}
`;

const PaginationLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const PageLinkButton = styled.button<{
  highlight?: boolean;
}>`
  display: ${({ highlight }) => (highlight ? 'block' : 'none')};
  height: 40px;
  color: ${({ highlight, theme }) =>
    highlight ? theme.colors.GREY_DARKER : theme.colors.GREY_DARK};
  font-weight: ${({ highlight, theme }) =>
    highlight ? theme.fontWeight.bold : theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: pointer;
  ${({ highlight, theme }) =>
    highlight
      ? `border: 2px solid ${theme.colors.GREY_DARKER}`
      : `border: 1px solid ${theme.colors.GREY_DARK}`};
  padding: 0 ${({ theme }) => theme.spacing.M16};
  ${media.large} {
    margin-right: ${({ theme }) => theme.spacing.M24};
    display: block;
  }
  &:hover {
    color: ${({ highlight, theme }) =>
      highlight ? theme.colors.GREY_DARKER : theme.colors.GREY_DARK};
  }
`;

const StyledBreak = styled.span`
  display: none;

  ${media.large} {
    ${({ theme }) => theme.fontSize.M16}
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 40px;
    cursor: default;
    margin-right: ${({ theme }) => theme.spacing.M24};
  }
`;

const PaginationResultsData = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  text-align: center;
`;

function Pagination(props: IPagination) {
  const {
    currentPage,
    pageSize,
    totalPages,
    totalResults,
    goToStart,
    goToEnd,
  } = props;
  const formattedDisplayingFrom = formatNumber(
    (currentPage - 1) * pageSize + 1,
  );
  const formattedDisplayingTo = formatNumber(
    pageSize * currentPage > totalResults
      ? totalResults
      : pageSize * currentPage,
  );
  const formattedTotalResults = formatNumber(totalResults);
  const displayFirstAndLast = Boolean(goToStart && goToEnd);

  return (
    <>
      <PaginationLinks>
        {displayFirstAndLast && (
          <BookendGhostButtonContainer>
            <GhostButton
              onClick={props.goToStart}
              disabled={currentPage === 1}
              size="SMALL"
            >
              <ChevronBackOutlineVariantIc />
            </GhostButton>
          </BookendGhostButtonContainer>
        )}
        <SPagination
          searchQueryGroup=""
          renderBreakView={(props) => (
            <StyledBreak {...props}>{'...'}</StyledBreak>
          )}
          renderPageButton={(props) => {
            const { onClick, text } = props;
            return (
              <PageLinkButton
                onClick={onClick}
                highlight={currentPage === text}
              >
                {formatNumber(text)}
              </PageLinkButton>
            );
          }}
          renderPrevButton={(props) => (
            <NavigationGhostButtonContainer>
              <NavigationGhostButton
                data-testid="go-to-prev-page"
                includeMargin
                {...props}
                size="SMALL"
              >
                <ChevronBackOutlineIc />
                <span>Prev</span>
              </NavigationGhostButton>
            </NavigationGhostButtonContainer>
          )}
          renderNextButton={(props) => (
            <NavigationGhostButtonContainer>
              <NavigationGhostButton
                data-testid="go-to-next-page"
                size="SMALL"
                {...props}
              >
                <span>Next</span>
                <ChevronForwardOutlineIc />
              </NavigationGhostButton>
            </NavigationGhostButtonContainer>
          )}
          {...props}
        />
        {displayFirstAndLast && (
          <BookendGhostButtonContainer>
            <GhostButton
              onClick={goToEnd}
              disabled={currentPage === totalPages}
              size="SMALL"
            >
              <ChevronForwardOutlineVariantIc />
            </GhostButton>
          </BookendGhostButtonContainer>
        )}
      </PaginationLinks>
      <PaginationResultsData data-testid="pagination-results">
        Showing {formattedDisplayingFrom} - {formattedDisplayingTo} of{' '}
        {formattedTotalResults}
      </PaginationResultsData>
    </>
  );
}

export { Pagination };
