import type { ListProps } from 'components/ToolkitV2/SearchableDropdown/components/List/List.type';
import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/List/List.styled';

const List = (props: ListProps) => {
  const { children, isLoading, loadingCount, isExpanded, dataTestId } = props;
  const count = loadingCount || 10;
  if (!isExpanded) return <></>;
  if (isLoading) {
    return (
      <Styled.List data-testid={`${dataTestId}-loading`}>
        {Array.apply(null, Array(count)).map((_: null, index: number) => (
          <Styled.SItem key={index}>
            <SkeletonBlock w={'100%'} h={24} />
          </Styled.SItem>
        ))}
      </Styled.List>
    );
  } else {
    return <Styled.List data-testid={dataTestId}>{children}</Styled.List>;
  }
};

export default List;
