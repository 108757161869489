import styled from 'styled-components';

export const Item = styled.li`
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1px;
  :hover {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
`;

export const Clickable = styled.button`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  width: 100%;
  height: 100%;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  text-align: inherit;
`;
