import styled, { keyframes } from 'styled-components';

type Size = 'DEFAULT' | 'LARGE';

type Color = 'LIGHT' | 'DARK';

const LoadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Loading = styled.div<{ size?: Size; color?: Color; cdnUrl: string }>`
  display: inline-block;
  vertical-align: middle;
  width: ${({ size }) => (size === 'LARGE' ? 40 : 24)}px;
  height: ${({ size }) => (size === 'LARGE' ? 40 : 24)}px;
  animation: 1s ${LoadingAnimation} infinite linear;
  background-image: url('${({ cdnUrl, color }) =>
    `${cdnUrl}/images/illustrations/${
      color === 'LIGHT' ? 'loading-light' : 'loading'
    }.svg`}');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
`;

export { Loading };
