import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import { formatLocation } from 'components/DealerDirectory/helpers/index.format';

/**
 * This function takes the selected value and returns it along with a display friendly value
 * @param selected the selected value
 * @returns the selected value and a display friendly value
 */
export const formatSelectedLocation = (
  selected?: string,
): DealerDirectoryFilterSelected => ({
  displayName: formatLocation(selected),
  value: selected ?? null,
});
