import type { GetServerSidePropsResult } from 'next/types';
import StatusCode from 'status-code-enum';

type Type = 'success' | 'error';

export type ErrorServerSideProps = {
  type: Type;
  code: StatusCode | 'default';
  domain?: string;
};

export type ServerSideProps<T = any> = ErrorServerSideProps | T;

export type ServerResponse<T> = Promise<
  GetServerSidePropsResult<ServerSideProps<T>>
>;

export function isServerError(
  props: ServerSideProps,
): props is ErrorServerSideProps {
  return props.type === 'error';
}

export function isServerSuccess<T extends { type: Type }>(
  props: ServerSideProps<T>,
): props is T {
  return props.type === 'success';
}
