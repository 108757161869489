import type { IncomingHttpHeaders } from 'http';

import type { Gateway, DataSourceResponse } from 'data-source/data-source.type';
import type {
  GetManyPayload,
  GetByNamePayload,
  DealerGetManyResponse,
  FranchiseGetManyResponse,
  DealersAutocompleteResponse,
} from 'data-source/DealerDataSource/DealerDataSource.type';
import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import type {
  DealerShowroom,
  DealerShowroomPhone,
  DealerShowroomSeo,
} from 'api/types/dealerApiTypes';

export class DealerDataSource {
  constructor(private gateway: Gateway) {}

  async getMany(
    payload: Partial<GetManyPayload>,
    headers?: IncomingHttpHeaders,
  ): DataSourceResponse<DealerGetManyResponse> {
    return await this.gateway.post<DealerGetManyResponse>(
      `/ddapi/v1/dealer/directory/search`,
      payload,
      {
        headers,
      },
    );
  }

  async getByName(
    name: string,
    payload: GetByNamePayload,
    headers: IncomingHttpHeaders,
  ): DataSourceResponse<DealerShowroom> {
    return await this.gateway.post(
      `/ddapi/v1/search/showroom/${name}`,
      payload,
      {
        headers,
      },
    );
  }

  async getShowroomFilters(
    id: number,
    section: string,
  ): DataSourceResponse<ISearchPageFilter[]> {
    return await this.gateway.get(
      `/ddapi/v1/search/showroom/filters/${section}?dealerId=${id}`,
    );
  }

  async getShowroomPhone(id: number): DataSourceResponse<DealerShowroomPhone> {
    return await this.gateway.get(
      `/ddapi/legacy/search/api/v4/dealer/${id}/phone/?enquirySource=dealerShowroom`,
    );
  }

  async getShowroomSEO(id: number): DataSourceResponse<DealerShowroomSeo> {
    return await this.gateway.get(`/ddapi/v1/seo/showroom/${id}`);
  }

  async getFranchises(
    headers?: IncomingHttpHeaders,
  ): DataSourceResponse<FranchiseGetManyResponse> {
    return await this.gateway.get(
      '/ddapi/v1/dealer/directory/franchise-groups',
      { headers },
    );
  }

  async autocomplete(
    term: string | null,
    headers?: IncomingHttpHeaders,
  ): DataSourceResponse<DealersAutocompleteResponse> {
    return await this.gateway.get(
      `/ddapi/v1/dealer/autocomplete${term ? `?text=${term}` : ''}`,
      { headers },
    );
  }
}
