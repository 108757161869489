import { useState, useRef } from 'react';
import { useRouter } from 'next/router';

import type { FranchiseFilterProps } from 'components/DealerDirectory/components/FranchiseFilter/FranchiseFilter.typed';
import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { getFilterByValue, getGroupValues } from 'domains/Filter';
import { useClickOutside } from 'hooks/useClickOutside';
import { formatSelectedFranchise } from 'components/DealerDirectory/components/FranchiseFilter/FranchiseFilter.format';
import { mapParametersToQuery } from 'components/DealerDirectory/helpers/index.mapper';

type Franchise = FranchiseFilterProps['filter'] & {
  placeholder: string;
  selected: DealerDirectoryFilterSelected;
};

const useFranchiseFilter = (props: FranchiseFilterProps) => {
  const { filter, onSelect } = props;
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const query = mapParametersToQuery(router.query);
  const selectedFranchise = query.franchises;
  const filterValues = getGroupValues(filter);
  const franchise = selectedFranchise
    ? getFilterByValue(filterValues, selectedFranchise)
    : null;

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const openMobile = () => setIsMobileOpen(true);
  const closeMobile = () => setIsMobileOpen(false);

  const getFranchise = (): Franchise => {
    return {
      ...filter,
      placeholder: filter.values[0].values[0].displayName,
      selected: formatSelectedFranchise(franchise),
    };
  };

  const handleSelect = (screen: 'mobile' | 'desktop') => (item: ListItem) => {
    switch (screen) {
      case 'mobile':
        closeMobile();
        break;
      case 'desktop':
        close();
    }
    onSelect(item);
  };

  useClickOutside({ active: isOpen, ref, onClickOutside: close });

  return {
    data: getFranchise(),
    isOpen,
    isMobileOpen,
    ref,
    handleSelect,
    open,
    close,
    openMobile,
    closeMobile,
  };
};

export { useFranchiseFilter };
