import { ChevronForwardIc } from '@dsch/dd-icons';

import * as Styled from 'components/ToolkitV2/Breadcrumbs/Components/Divider/Divider.styled';

const Divider = (props: { inverted?: boolean }) => {
  const { inverted } = props;

  return (
    <Styled.ForwardArrowContainer inverted={inverted}>
      <ChevronForwardIc width="12" height="12" aria-hidden="true" />
    </Styled.ForwardArrowContainer>
  );
};

export { Divider };
