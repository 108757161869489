import type { ReactNode } from 'react';

import type { ThemeTypes } from 'types/Theme.namespace';

enum ORIENTATION {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

enum VIEW_TYPE {
  LIST = 'list',
  GRID = 'grid',
}

interface OrientationProps {
  orientationSmall: ORIENTATION;
  orientationMedium?: ORIENTATION;
  orientationLarge?: ORIENTATION;
}

interface CardProps extends OrientationProps {
  header: ReactNode;
  image?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  isDisabled?: boolean;
  className?: string;
  paddingSmall: ThemeTypes.Spacing;
  paddingMedium?: ThemeTypes.Spacing;
  paddingLarge?: ThemeTypes.Spacing;
  gapSmall?: ThemeTypes.Spacing;
  gapMedium?: ThemeTypes.Spacing;
  gapLarge?: ThemeTypes.Spacing;
  fillContainer?: boolean;
  trailingImageSmall?: boolean;
  trailingImageMedium?: boolean;
  trailingImageLarge?: boolean;
  backgroundColor?: ThemeTypes.Colors;
}

export { ORIENTATION, VIEW_TYPE };
export type { OrientationProps, CardProps };
