// As the API returns a full url and we want to do client side
// routing, we must remove the host. We normally can achieve this
// just using the domain variable. But for the time being on
// preview we are hitting DDT which returns different domains
// than the one we are on

function convertToFriendlyPath(
  domain: string,
  friendlyUrl: string,
  updateCampaignNumber?: number,
) {
  // TODO: This is probably bad as it will be hidden. We need to investigate this.
  if (!friendlyUrl) {
    return '/';
  }
  const initialFriendlyPath = friendlyUrl
    ?.replace(domain, '')
    .replace('https://www.devdd.com', '')
    .replace('https://www.donedealtest.com', '')
    .replace('https://www.donedeal.adview', '')
    .replace('https://www.donedealtest.adview', '')
    .replace('https://www.donedealtest.ie', '')
    .replace('https://www.donedeal.ie', '');

  const friendlyPath = !updateCampaignNumber
    ? initialFriendlyPath
    : initialFriendlyPath?.replace(
        /\?campaign=\d+$/,
        `?campaign=${updateCampaignNumber}`,
      );

  return friendlyPath;
}

export default convertToFriendlyPath;
