import getConfig from 'next/config';
import type { NextPageContext } from 'next';

import { DefaultLayout } from 'components/Layouts';
import { ErrorPage } from 'components/ErrorPages/ErrorPage';

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS },
} = getConfig();

interface IErrorPageProps {
  statusCode: number | 'default';
  pageVariant?: string;
  disableGtmLog?: boolean;
  domain?: string;
}

function Error({ statusCode, disableGtmLog, domain = '' }: IErrorPageProps) {
  const errorSelector = (statusCode: number | 'default') => {
    switch (statusCode) {
      default:
        return (
          <ErrorPage
            status={statusCode}
            cdnUrl={CDN_STATIC_ASSETS}
            disableGtmLog={disableGtmLog}
          />
        );
    }
  };

  return (
    <DefaultLayout domain={domain}>{errorSelector(statusCode)}</DefaultLayout>
  );
}

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
