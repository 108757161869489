import styled from 'styled-components';
import type { ReactNode } from 'react';

import { media } from 'helpers/breakpoints';

type TMobileProps = {
  children: ReactNode;
  display?: string;
  className?: string;
};
type TMobile = (props: TMobileProps) => JSX.Element;

type TContainer = { display: string };
const Container = styled.div<TContainer>`
  display: ${({ display }) => display};

  ${media.medium} {
    display: none;
  }
`;

const Mobile: TMobile = ({ children, display = 'block', className }) => {
  return (
    <Container display={display} className={className}>
      {children}
    </Container>
  );
};

export { Mobile };
