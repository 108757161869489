import type { FranchiseFilterProps } from 'components/DealerDirectory/components/FranchiseFilter/FranchiseFilter.typed';
import { useFranchiseFilter } from 'components/DealerDirectory/components/FranchiseFilter/FranchiseFilter.hook';
import { Mobile } from 'components/Support/Mobile/Mobile';
import { Desktop } from 'components/Support/Desktop/Desktop';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { CustomSelect } from 'components/Toolkit/Inputs/CustomSelect';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const FranchiseFilter = (props: FranchiseFilterProps) => {
  const {
    data,
    isMobileOpen,
    isOpen,
    ref,
    handleSelect,
    open,
    openMobile,
    closeMobile,
  } = useFranchiseFilter(props);
  const { name, values, placeholder, selected } = data;

  return (
    <Styled.FilterContainer>
      <Mobile>
        <InputButton
          heightVariant="LARGE"
          isActive={isMobileOpen}
          value={selected.displayName}
          placeholder={placeholder}
          onClick={openMobile}
          dataTestId="franchise-dropdown-button-mobile"
        />
        <BottomSheet
          headerText={selected.displayName}
          onApply={closeMobile}
          askToClose={closeMobile}
          isOpen={isMobileOpen}
          optionSelected={Boolean(selected.value)}
          resetScrollPosition={isMobileOpen}
        >
          <CustomSelect
            listData={values}
            onSelect={handleSelect('mobile')}
            dataTestId="franchise-dropdown-list-mobile"
            selectedItem={selected}
            highlightSelected
          />
        </BottomSheet>
      </Mobile>
      <Desktop>
        <div ref={ref}>
          <Dropdown
            expanded={isOpen}
            setExpanded={open}
            placeholder={placeholder}
            displayedValue={selected.displayName}
            heightVariant="LARGE"
            data-role="listbox"
            id={name}
            dataTestId="franchise-dropdown-button-desktop"
          >
            <CustomSelect
              listData={values}
              onSelect={handleSelect('desktop')}
              dataTestId="franchise-dropdown-list-desktop"
              selectedItem={selected}
              highlightSelected
            />
          </Dropdown>
        </div>
      </Desktop>
    </Styled.FilterContainer>
  );
};

export { FranchiseFilter };
