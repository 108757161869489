import { useState, useRef } from 'react';
import { useRouter } from 'next/router';

import type { DealersFilterProps } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.typed';
import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import { useClickOutside } from 'hooks/useClickOutside';
import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { mapParametersToQuery } from 'components/DealerDirectory/helpers/index.mapper';
import { getFilterByValue } from 'domains/Filter';
import * as constants from 'components/DealerDirectory/constants';

export type DealersData = DealersFilterProps['filter'] & {
  placeholder: string;
  selected: DealerDirectoryFilterSelected;
};

const useDealersFilter = (props: DealersFilterProps) => {
  const { filter, onSelect } = props;
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const query = mapParametersToQuery(router.query);
  const selectedDealer = query.terms;
  const dealers = selectedDealer
    ? getFilterByValue(filter?.values, selectedDealer)
    : null;

  const getDealers = (): DealersData => ({
    ...filter,
    placeholder: filter.values[0].displayName,
    selected: {
      displayName:
        dealers?.displayName || selectedDealer || constants.DEFAULT_TERMS_TEXT,
      value: dealers?.value || null,
    },
  });

  const handleSelect = (screen: 'mobile' | 'desktop') => (item: ListItem) => {
    switch (screen) {
      case 'mobile':
        closeMobile();
        break;
      case 'desktop':
        close();
    }
    onSelect(item);
  };

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const openMobile = () => setIsMobileOpen(true);
  const closeMobile = () => setIsMobileOpen(false);

  useClickOutside({ active: isOpen, ref, onClickOutside: close });

  return {
    data: getDealers(),
    isOpen,
    isMobileOpen,
    ref,
    handleSelect,
    open,
    close,
    openMobile,
    closeMobile,
  };
};

export { useDealersFilter };
