export type Result<E, A> = ResultError<E> | ResultOk<A>;

export interface ResultError<E> {
  readonly _tag: 'ResultError';
  readonly error: E;
}

export interface ResultOk<A> {
  readonly _tag: 'ResultOk';
  readonly ok: A;
}

const _isError = <E>(ma: Result<E, unknown>): ma is ResultError<E> =>
  ma._tag === 'ResultError';
const _isOk = <A>(ma: Result<unknown, A>): ma is ResultOk<A> =>
  ma._tag === 'ResultOk';

const _error = <E, A = never>(e: E): Result<E, A> => ({
  _tag: 'ResultError',
  error: e,
});
const _ok = <A, E = never>(a: A): Result<E, A> => ({ _tag: 'ResultOk', ok: a });

export const isError: <E>(ma: Result<E, unknown>) => ma is ResultError<E> =
  _isError;
export const isOk: <A>(ma: Result<unknown, A>) => ma is ResultOk<A> = _isOk;
export const error: <E = never, A = never>(e: E) => Result<E, A> = _error;
export const ok: <E = never, A = never>(a: A) => Result<E, A> = _ok;
