import { ReactNode } from 'react';
import styled from 'styled-components';

import type { ThemeTypes } from 'types/Theme.namespace';

export interface IColumnsProps {
  children: ReactNode;
  gap?: ThemeTypes.Spacing;
}

const Container = styled.div<{ gap: ThemeTypes.Spacing }>`
  display: flex;
  display: grid;
  grid-gap: ${({ gap, theme }) => theme.spacing[gap]};
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
`;

const Column = styled.div`
  flex: 1 0 auto;
`;

function Columns({ gap = 'S8', children }: IColumnsProps) {
  const items = Array.isArray(children) ? children : [children];
  return (
    <Container gap={gap}>
      {items
        .filter((item) => item && item)
        .map((child, index) => (
          <Column key={index}>{child}</Column>
        ))}
    </Container>
  );
}

export { Columns };
