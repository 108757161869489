import type { AxiosResponse } from 'axios';

import { getToday, isCurrentlyOpen } from 'helpers/openingHours';
import type { Franchises, Autocomplete } from 'domains/Dealer';
import type {
  DealerGetManyResponse,
  FranchiseGetManyResponse,
  DealersAutocompleteResponse,
} from 'data-source/DealerDataSource/DealerDataSource.type';
import type { SearchSuccess } from 'repositories/Dealer/DealerRepository.type';

// TODO: Merge with Dealer showoom mapper
export const search = (
  response: AxiosResponse<DealerGetManyResponse>,
): SearchSuccess => {
  const today = getToday().getDay();
  const { data } = response;
  const mappedResponse: SearchSuccess = {
    dealers: data.dealers
      ? data.dealers.map((dealer) => {
          const weekday = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          const hasAppointmentOnly =
            dealer.enhancedOpeningHours &&
            dealer.enhancedOpeningHours.days &&
            dealer.enhancedOpeningHours.days.filter(
              (item) => item.appointmentOnly,
            ).length == 7;

          const workingHours = dealer.enhancedOpeningHours
            ? dealer.enhancedOpeningHours.days.map((item) => ({
                day: item.day,
                openTime: item.openTime,
                closeTime: item.closeTime,
                appointmentOnly: Boolean(item.appointmentOnly),
                appointmentOnlyText: item.appointmentOnlyText || '',
                isCurrentOpen:
                  Boolean(item.day === weekday[today]) &&
                  isCurrentlyOpen(item.openTime, item.closeTime),
              }))
            : null;

          return {
            id: dealer.id.toString(),
            slug: dealer.showroomUrl,
            name: dealer.name,
            email: dealer.email,
            description: dealer.description,
            info: dealer.franchisesDisplay,
            address: dealer?.enhancedAddress
              ? dealer.enhancedAddress.addressLocality
                ? `${dealer.enhancedAddress.addressLocality}, ${dealer.enhancedAddress.addressRegion}`
                : `${dealer.enhancedAddress.addressRegion}`
              : dealer.address,
            fullAddress: dealer.address,
            logo: dealer.logo?.small || null,
            logoMedium: dealer.logo?.medium || null,
            backgroundImage: dealer.forecourtPhoto?.large || null,
            backgroundImageSmall: dealer.forecourtPhoto?.small || null,
            rating:
              dealer.googleReview?.rating && dealer.googleReview.displayReviews
                ? Number(dealer.googleReview.rating)
                : null,
            googleReview:
              dealer.googleReview && dealer.googleReview.displayReviews
                ? dealer.googleReview
                : null,
            region: dealer?.enhancedAddress
              ? dealer.enhancedAddress?.addressRegion
              : null,
            workingHours,
            googleMapsLink: `https://www.google.com/maps/place/${dealer.latitude},${dealer.longitude}/@${dealer.latitude},${dealer.longitude}z/`,
            googleMapPhoto:
              dealer.googleMapPhoto && dealer.googleMapPhoto.small
                ? dealer.googleMapPhoto.small
                : null,
            ratingTotal: dealer.googleReview
              ? Number(dealer.googleReview.userRatingsTotal)
              : null,
            website: dealer.websiteURL || '',
            hasAppointmentOnly: !!hasAppointmentOnly,
            stock: dealer.totalAds || null,
            trusted: dealer?.trustedDealer || null,
            sections: dealer.sections || null,
          };
        })
      : [],
    paging: {
      currentPage: data.pagingCounts.currentPage,
      displayingFrom: data.pagingCounts.previousStart,
      displayingTo: data.pagingCounts.nextStart,
      nextFrom: data.pagingCounts.nextStart,
      pageSize: data.pagingCounts.paginationPageSize,
      previousFrom: data.pagingCounts.previousStart,
      totalPages: Math.ceil(
        data.pagingCounts.total / data.pagingCounts.paginationPageSize,
      ),
      totalResults: data.pagingCounts.total,
    },
    count: data.dealerCount,
    promoSlot: data.promoSlot || null,
  };
  return mappedResponse;
};

export const franchises = (
  response: AxiosResponse<FranchiseGetManyResponse>,
): Franchises[] => {
  const mappedResponse: Franchises[] = response.data.map((data) => ({
    groupDisplayName: data.displayLabel,
    values: data?.franchises
      ? data.franchises.map((franchise) => ({
          displayName: franchise.display,
          value: franchise.value,
        }))
      : [],
  }));
  return mappedResponse;
};

export const autocomplete = (
  response: AxiosResponse<DealersAutocompleteResponse>,
): Autocomplete =>
  response.data.suggestions.map((item) => ({
    displayName: item.name,
    value: item.name,
  }));
