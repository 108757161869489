import React from 'react';
import type { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  BaseButtonCSS,
  CommonProps,
  DisabledCSS,
  FocusCSS,
} from 'components/Toolkit/Button/SharedButton';

type ButtonSize = 'SMALL' | 'LARGE';

export interface IGhostButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
}

interface SGhostButtonProps
  extends CommonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  ofType?: 'GHOST';
}

const SGhostButton = styled.button<SGhostButtonProps>`
  ${BaseButtonCSS};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.S4};
  min-width: 40px;

  &:focus-visible {
    ${FocusCSS}
  }

  &:disabled {
    ${DisabledCSS}
  }
`;

/**
 * These are buttons with most styles striped
 */
function GhostButton({ children, className, ...rest }: IGhostButton) {
  return (
    <SGhostButton className={className} {...rest} ofType={'GHOST'}>
      {children}
    </SGhostButton>
  );
}

export { GhostButton };
