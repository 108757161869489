import { BasicHeader } from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader';
import { Price } from 'components/ToolkitV2/CardV2/components/Price/Price';
import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';

import convertToFriendlyPath from 'utils/FriendlyPath';
import { useCampaignURLHandler } from 'components/PageWrappers/Hooks/Campaigns/UseCampaignURLHandler';
import { accessibleOnClick } from 'utils/Accessibility';

import * as Styled from 'components/Toolkit/FeaturedDealer/FeaturedDealer.styled';

import type { FeaturedDealerProps } from 'components/Toolkit/FeaturedDealer/FeaturedDealer.typed';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';

const FeaturedDealer = (props: FeaturedDealerProps) => {
  const {
    heading,
    title,
    listItems,
    showRoomText,
    showRoomUrl,
    dealerLogo,
    ads,
    domain,
    updateCampaignNumber,
    testId,
  } = props;
  const { handleCampaignAdRouterPush } = useCampaignURLHandler();
  const handleShowroomButtonClick = () =>
    handleCampaignAdRouterPush(showRoomUrl);
  const count = ads ? ads.length : 0;

  return (
    <>
      {ads && ads.length !== 0 && (
        <Styled.Container>
          <div className="row small-collapse medium-uncollapse">
            <div className="columns small-12">
              {heading && <Styled.Heading>{heading}</Styled.Heading>}
              <Styled.Wrapper>
                <Styled.InfoContainer count={count}>
                  <Styled.HeadingWrapper>
                    <Styled.Title>{title}</Styled.Title>
                  </Styled.HeadingWrapper>
                  <Styled.HeadingWrapper>
                    {listItems && (
                      <Styled.SubtitleList>
                        {listItems.map(
                          (item, index) =>
                            item && (
                              <Styled.SubtitleItem
                                key={`featured-dealer-subtitle-item-${index}`}
                              >
                                {item}
                              </Styled.SubtitleItem>
                            ),
                        )}
                      </Styled.SubtitleList>
                    )}
                  </Styled.HeadingWrapper>
                  <Styled.SeeShowroomButton
                    {...accessibleOnClick(handleShowroomButtonClick, 'link')}
                    ofType="INVERTED"
                    underline
                  >
                    {showRoomText}
                  </Styled.SeeShowroomButton>
                  <Styled.FeaturedTag text="FEATURED" bgColor="ORANGE" />
                  {dealerLogo && (
                    <Styled.DealerLogoContainer>
                      <Styled.DealerLogo src={dealerLogo} alt="Dealer Logo" />
                    </Styled.DealerLogoContainer>
                  )}
                </Styled.InfoContainer>
                <Styled.FeaturedDealerCardsContainer
                  count={count}
                  data-testid={testId}
                >
                  {ads.map((ad) => {
                    const {
                      id,
                      header,
                      photos,
                      friendlyUrl,
                      currency,
                      price,
                      oldPriceView,
                      financeSummary,
                      imageAlt,
                    } = ad;

                    const friendlyPath = convertToFriendlyPath(
                      domain,
                      friendlyUrl!,
                      updateCampaignNumber!,
                    );
                    const src =
                      photos && photos[0].small2 ? photos[0].small2 : undefined;
                    const imgSrcWebp =
                      photos && photos[0].small2Webp
                        ? photos[0].small2Webp
                        : undefined;

                    const oldPrice =
                      oldPriceView?.showOldPrice && oldPriceView.showOldPrice
                        ? oldPriceView.oldPrice
                        : null;

                    const handleClick = () =>
                      handleCampaignAdRouterPush(friendlyPath);

                    return (
                      <li
                        key={`featured-dealer-card-${id}`}
                        // Our Google Analytics campaign budget counts any URLs containing the
                        // campaign query param when it counts up the budget. If we use a fake
                        // button link here we can avoid a bit of cost. This means we need to add
                        // the necessary attributes to make the <li> element behave like a link
                        role="button"
                        tabIndex={0}
                        onClick={handleClick}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleClick();
                          }
                        }}
                        aria-label={`View ${header}`}
                      >
                        <Styled.FeaturedDealerCard
                          count={count}
                          orientationSmall={ORIENTATION.VERTICAL}
                          paddingSmall="S8"
                          header={<BasicHeader title={header} />}
                          image={
                            <Image
                              orientationSmall={ORIENTATION.VERTICAL}
                              src={src}
                              imgSrcWebp={imgSrcWebp}
                              alt={imageAlt}
                              heightVertical={182}
                            />
                          }
                          footer={
                            <Price
                              currency={currency}
                              price={price}
                              oldPrice={oldPrice}
                              pricePerMonth={financeSummary?.price}
                            />
                          }
                        />
                      </li>
                    );
                  })}
                </Styled.FeaturedDealerCardsContainer>
              </Styled.Wrapper>
            </div>
          </div>
        </Styled.Container>
      )}
    </>
  );
};

export { FeaturedDealer };
