import type { DealersProps } from 'components/DealerDirectory/components/Dealers/Dealers.typed';
import { useDealers } from 'components/DealerDirectory/components/Dealers/Dealers.hook';
import { Spacer } from 'helpers/genericStyles';
import { DealerCard } from 'components/DealerDirectory/components/DealerCard/DealerCard';
import { DealerCardSkeleton } from 'components/DealerDirectory/components/DealerCard/DealerCard.skeleton';
import { NoResults } from 'components/DealerDirectory/components/NoResults/NoResults';
import { Pagination } from 'components/Toolkit/Pagination/Pagination';
import * as constants from 'components/DealerDirectory/constants';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const Dealers = (props: DealersProps) => {
  const {
    seoContent,
    dealers,
    paging,
    isPaging,
    isLoading,
    isDealers,
    onPageChange,
    goto,
  } = useDealers(props);
  return (
    <>
      <Styled.FilterText data-testid="dealers-count-heading">
        {seoContent.countInLocationText}
      </Styled.FilterText>
      {isLoading ? (
        <LoadingCards />
      ) : isDealers ? (
        <>
          <ul data-testid="dealers-list">
            {dealers.map((dealer) => (
              <Styled.DealerCardWrapper
                key={dealer.id}
                data-testid={`dealers-listitem-${dealer.id}`}
              >
                <DealerCard dealer={dealer} />
              </Styled.DealerCardWrapper>
            ))}
          </ul>
          {isPaging && (
            <>
              <Spacer marginBottom={['L40', 'L40', 'M24']} />
              <Styled.PaginationWrapper>
                <Styled.PaginationContainer>
                  <Pagination
                    {...paging}
                    onUpdate={onPageChange}
                    goToStart={() => goto('start')}
                    goToEnd={() => goto('end')}
                  />
                </Styled.PaginationContainer>
              </Styled.PaginationWrapper>
            </>
          )}
          <Spacer marginBottom={['M16', 'M16', 'L40']} />
        </>
      ) : (
        <NoCards />
      )}
    </>
  );
};

const LoadingCards = () => {
  return (
    <ul>
      {Array.apply(null, Array(constants.DEFAULT_RESULTS_AMOUNT)).map(
        (_: null, index: number) => {
          return (
            <Styled.DealerCardWrapper key={index}>
              <DealerCardSkeleton />
            </Styled.DealerCardWrapper>
          );
        },
      )}
    </ul>
  );
};

const NoCards = () => {
  return (
    <>
      <Spacer marginBottom={['M24']} />
      <NoResults />
      <Spacer marginBottom={['XL80']} />
    </>
  );
};

export { Dealers };
