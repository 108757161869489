import styled from 'styled-components';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { Tag } from 'components/Toolkit/Tags/Tag';
import { Card } from 'components/ToolkitV2/CardV2/Card';

import { media } from 'helpers/breakpoints';

interface InfoContainerProps extends FeaturedDealerCardProps {}

interface FeaturedDealerCardsContainerProps extends FeaturedDealerCardProps {}

interface FeaturedDealerCardProps {
  count: number;
}

const CARD_WIDTH = '242px';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => `${theme.spacing.M16} 0`};

  ${media.large} {
    padding: ${({ theme }) => `${theme.spacing.M24} 0`};
  }
`;

const Heading = styled.h2`
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme }) => theme.colors.WHITE};
  ${media.smallOnly} {
    padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.M24};
  }
`;

const InfoContainer = styled.div<InfoContainerProps>`
  padding: ${({ theme }) =>
    `0 ${theme.spacing.M16} ${theme.spacing.M16} ${theme.spacing.M16}`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    padding: ${({ theme }) => `0 0 ${theme.spacing.M16} 0`};
  }

  ${media.large} {
    order: 1;
    padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
    margin-bottom: 0;
    width: ${({ count }) => (count === 3 ? 'calc((1 / 3) * 100%)' : '100%')};
  }
`;

const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 64px;
  column-gap: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    padding-right: 0;
    display: block;
  }
`;

const Title = styled.p`
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme }) => theme.colors.WHITE};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${media.large} {
    ${({ theme }) => theme.fontSize.L24};
  }
`;

const SubtitleList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const SubtitleItem = styled.li`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
  display: flex;
  align-items: center;

  &:after {
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.colors.GREY_LIGHT};
    margin-left: ${({ theme }) => theme.spacing.S8};
    margin-right: ${({ theme }) => theme.spacing.S8};
    content: '';
    border-radius: 50%;
  }

  &:last-child {
    &:after {
      width: 4px;
      content: none;
      height: 100%;
    }
  }
`;

const SeeShowroomButton = styled(LinkButton)`
  margin-bottom: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.S12};
  }
`;

const FeaturedTag = styled(Tag)`
  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.S12};
  }
`;

const DealerLogoContainer = styled.div`
  width: 64px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;

  ${media.medium} {
    right: 0;
  }

  ${media.large} {
    position: initial;
    width: 160px;
    height: 80px;
  }
`;

const DealerLogo = styled.img`
  width: 64px;
  height: 32px;

  ${media.large} {
    width: 160px;
    height: 80px;
  }
`;

const FeaturedDealerCardsContainer = styled.ul<FeaturedDealerCardsContainerProps>`
  display: flex;
  overflow-x: auto;
  gap: ${({ theme }) => theme.spacing.M16};
  padding: ${({ theme }) =>
    `0 ${theme.spacing.M16} ${theme.spacing.M16} ${theme.spacing.M16}`};

  ${media.medium} {
    padding: ${({ theme }) => `0 0 ${theme.spacing.M16} 0`};
  }

  ${media.large} {
    padding: 0;
    margin-right: ${({ theme }) => theme.spacing.M16};
    width: ${({ count }) => (count === 3 ? 'calc((2 / 3) * 100%)' : '100%')};
  }
`;

const FeaturedDealerCard = styled(Card)<FeaturedDealerCardProps>`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  width: ${({ count }) => (count === 1 ? '100%' : CARD_WIDTH)};
  overflow: hidden;
  cursor: pointer;

  ${media.medium} {
    width: ${({ count }) => (count < 3 ? CARD_WIDTH : '100%')};
  }

  ${media.large} {
    padding: 0;
    margin-right: ${({ theme }) => theme.spacing.M16};
  }
`;

export {
  Container,
  Heading,
  Wrapper,
  InfoContainer,
  HeadingWrapper,
  Title,
  SubtitleList,
  SubtitleItem,
  SeeShowroomButton,
  FeaturedTag,
  DealerLogoContainer,
  DealerLogo,
  FeaturedDealerCardsContainer,
  FeaturedDealerCard,
};
