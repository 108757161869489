import styled from 'styled-components';

import { Item } from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.styled';

export const List = styled.ul`
  ${({ theme }) => theme.fontSize.M14};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
`;

export const SItem = styled(Item)`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
`;
