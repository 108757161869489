import styled from 'styled-components';

import { Spacer } from 'helpers/genericStyles';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import { Card } from 'components/ToolkitV2/CardV2/Card';
import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const Inner = styled.div`
  display: flex;
`;

const Gap = styled.div`
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const SkeletonTitle = () => <SkeletonBlock w={280} h={24} />;

const SkeletonDescription = () => <SkeletonBlock w={96} h={14} />;

const SkeletonTag = () => <SkeletonBlock w={48} h={14} />;

const DealerCardSkeleton = () => {
  return (
    <Card
      header={
        <>
          <SkeletonTitle />
          <Spacer marginBottom={'M16'} />
          <SkeletonTag />
          <Spacer marginBottom={'M16'} />
          <Inner>
            <SkeletonDescription />
            <Gap />
            <SkeletonTag />
          </Inner>
          <Spacer marginBottom={'M16'} />
          <SkeletonTag />
        </>
      }
      image={
        <Styled.LogoWrapper
          orientationSmall={ORIENTATION.VERTICAL}
          orientationMedium={ORIENTATION.VERTICAL}
          orientationLarge={ORIENTATION.HORIZONTAL}
          paddingSmall="M16"
          paddingMedium="M16"
          paddingLarge="M24"
          trailingImageLarge
        >
          <SkeletonBlock w={80} h={40} wMedium={128} hMedium={64} />
        </Styled.LogoWrapper>
      }
      orientationSmall={ORIENTATION.VERTICAL}
      orientationMedium={ORIENTATION.VERTICAL}
      orientationLarge={ORIENTATION.HORIZONTAL}
      paddingSmall="M16"
      paddingMedium="M16"
      paddingLarge="M24"
      trailingImageLarge={true}
    />
  );
};

export { DealerCardSkeleton };
