import styled from 'styled-components';
import { ReactNode } from 'react';
import { media } from 'helpers/breakpoints';

type Position = 'static' | 'absolute' | 'fixed' | 'sticky';

export interface IStickyFooterProps {
  children: ReactNode;
  position?: Position;
  fade?: boolean;
  active?: boolean;
  className?: string;
}

type TContainer = {
  position?: Position;
  fade: boolean;
  active: boolean;
};
const Container = styled.div<TContainer>`
  position: ${({ position }) => position || 'fixed'};
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(72px)')};
  transition: transform 0.25s ease-in-out;
  padding: ${({ theme }) =>
    `${theme.spacing.S8} ${theme.spacing.M16} ${theme.spacing.M24}`};

  ${({ theme, fade }) =>
    fade
      ? `
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.92) 50.52%,
      rgba(255, 255, 255, 0.16) 100%
    );
    `
      : `background-color: ${theme.colors.WHITE}; box-shadow: ${theme.shadow.CONTAINER_INVERT};`}

  ${media.large} {
    padding-bottom: ${({ theme }) => theme.spacing.M16};
  }

  @media screen and (max-height: 600px) {
    padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  }
`;

function StickyFooter({
  className,
  position,
  fade = true,
  active = true,
  children,
  ...rest
}: IStickyFooterProps) {
  return (
    <Container
      className={className}
      position={position}
      fade={fade}
      active={active}
      {...rest}
    >
      {children}
    </Container>
  );
}

export { StickyFooter };
