import type { NextSeoProps } from 'next-seo';
import { useState } from 'react';
import { useRouter } from 'next/router';

import type { UseHeadProps } from 'components/DealerDirectory/components/Head/Head.typed';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { formatSEOMeta } from 'components/DealerDirectory/helpers/index.format';
import { mapParametersToQuery } from 'components/DealerDirectory/helpers/index.mapper';
import { CDN_STATIC_ASSETS } from 'utils';

const useHead = (props: UseHeadProps) => {
  const [seo, setSeo] = useState<NextSeoProps>(props.seo);
  const router = useRouter();
  const query = mapParametersToQuery(router.query);

  useOnUpdateOnly(() => {
    const meta = formatSEOMeta(CDN_STATIC_ASSETS, query.area);
    setSeo(meta);
  }, [router.query]);
  return { seo };
};

export { useHead };
