import type { SearchableDropdownProps } from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown.typed';
import Searchbox from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox';
import List from 'components/ToolkitV2/SearchableDropdown/components/List/List';
import ListItem from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem';
import useSearchableDropdown from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown.hook';

const Component = (props: SearchableDropdownProps) => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};

const SearchableDropdown = Object.assign(Component, {
  Searchbox,
  List,
  ListItem,
});

export default SearchableDropdown;
export { useSearchableDropdown };
