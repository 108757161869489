import styled from 'styled-components';

const ForwardArrowContainer = styled.div<{ inverted?: boolean }>`
  color: ${({ inverted, theme }) =>
    inverted ? theme.colors.GREY_LIGHTER : theme.colors.GREY_DARK};
  margin: 0 6px;
  display: flex;
  align-items: center;
`;

export { ForwardArrowContainer };
