function updateSiteFocusCookie(focus: string, domain: string) {
  if (typeof document !== 'undefined') {
    return (document.cookie = `ddSiteFocus=${focus.toLowerCase()}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; domain=${domain.replace(
      'https://www',
      '',
    )};`);
  }
}

export { updateSiteFocusCookie };
