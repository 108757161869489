import styled from 'styled-components';

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing.S8};
`;

export default TagList;
