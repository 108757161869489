import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import type { BaseFilterValue } from 'domains/Filter';
import * as constants from 'components/DealerDirectory/constants';

/**
 * This function takes the selected value and returns it along with a display friendly value
 * @param selected the selected value
 * @returns the selected value and a display friendly value
 */
export const formatSelectedFranchise = (
  selected?: BaseFilterValue | null,
): DealerDirectoryFilterSelected => ({
  displayName: selected?.displayName || constants.DEFAULT_FRANCHISE_TEXT,
  value: selected?.value || null,
});
