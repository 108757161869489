import { useRouter } from 'next/router';

import type { FilterV2, BaseFilterValue } from 'domains/Filter';
import type { DealerDirectoryFilterNames } from 'components/DealerDirectory/DealerDirectory.typed';
import { getFilterV2 } from 'domains/Filter';
import { FILTER_NAME } from 'components/DealerDirectory/DealerDirectory.typed';
import {
  mapParametersToQuery,
  mapQueryToParameters,
  mapfilterNameToQuery,
} from 'components/DealerDirectory/helpers/index.mapper';

type UseFilters = {
  filters: FilterV2[];
};

const useFilters = (props: UseFilters) => {
  const { filters } = props;
  const router = useRouter();
  const query = mapParametersToQuery(router.query);

  const locationFilter = getFilterV2<'BASE'>(filters, FILTER_NAME.AREA);
  const franchiseFilter = getFilterV2<'GROUP'>(filters, FILTER_NAME.FRANCHISES);
  const dealersFilter = getFilterV2<'BASE'>(filters, FILTER_NAME.TERMS);

  const formatParameters = (parameters: URLSearchParams) => {
    if (parameters.toString() === '') return '';
    return `?${parameters.toString()}`;
  };

  const push = async (parameters: URLSearchParams) =>
    await router.push(formatParameters(parameters), undefined, {
      shallow: true,
    });

  const onSelect =
    (filterName: DealerDirectoryFilterNames) =>
    async (item: BaseFilterValue) => {
      const queryKey = mapfilterNameToQuery(filterName);
      const value = item.value;
      const parameters = mapQueryToParameters(query, {
        clearPagination: true,
      });
      const urlSearchParams = new URLSearchParams({ ...parameters });
      if (value) urlSearchParams.set(queryKey, value);
      else urlSearchParams.delete(queryKey);
      await push(urlSearchParams);
    };

  return {
    locationFilter,
    franchiseFilter,
    dealersFilter,
    onSelect,
  };
};

export { useFilters };
