import styled, { css } from 'styled-components';

import { ChevronBackIc } from '@dsch/dd-icons';

import { Link } from 'components/Toolkit/Button/Link';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';

import { media } from 'helpers/breakpoints';

const Container = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.S12} 0;
`;

const BackIcon = styled(ChevronBackIc)`
  height: 24px;
  width: 24px;
  margin-left: -0.5rem; // added to compensate for extra space in icon

  ${media.medium} {
    height: 16px;
    width: 16px;
  }
`;

const BackButton = styled(LinkButton)`
  ${({ theme }) => theme.fontSize.L24}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  align-items: center;
  margin: auto ${({ theme }) => theme.spacing.M16} auto 0;

  ${media.medium} {
    ${({ theme }) => theme.fontSize.M16}
    margin: auto ${({ theme }) => theme.spacing.M24} auto 0;
  }
`;

const BackText = styled.span`
  display: none;

  ${media.medium} {
    ${({ theme }) => theme.fontSize.M14}
    margin-left: ${({ theme }) => theme.spacing.S4};
    display: inline-block;
  }
`;

const BreadcrumbsOuterContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome/Safari/Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BreadcrumbsInnerContainer = styled.div`
  display: flex;
`;

const BreadcrumbAndDividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumb = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

const BreadcrumbTextCSS = css`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const BreadcrumbLink = styled(Link)`
  ${BreadcrumbTextCSS};
`;

const BreadcrumbText = styled.span<{ inverted?: boolean }>`
  color: ${({ inverted, theme }) =>
    inverted ? theme.colors.GREY_LIGHT : theme.colors.GREY_DARK};
  ${BreadcrumbTextCSS};
`;

export {
  Container,
  BreadcrumbsOuterContainer,
  BreadcrumbsInnerContainer,
  BackIcon,
  BackButton,
  BackText,
  BreadcrumbAndDividerContainer,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbText,
};
