import { Fragment } from 'react';

import type { FilterManagerProps } from 'components/DealerDirectory/components/FilterManager/FilterManager.typed';
import { useFilters } from 'components/DealerDirectory/components/FilterManager/FilterManager.hook';
import { LocationFilter } from 'components/DealerDirectory/components/LocationFilter/LocationFilter';
import { FranchiseFilter } from 'components/DealerDirectory/components/FranchiseFilter/FranchiseFilter';
import { DealersFilter } from 'components/DealerDirectory/components/DealersFilter/DealersFilter';
import { FILTER_NAME } from 'components/DealerDirectory/DealerDirectory.typed';

const FilterManager = (props: FilterManagerProps) => {
  const { filters } = props;
  const { locationFilter, franchiseFilter, dealersFilter, onSelect } =
    useFilters({ filters });
  return (
    <Fragment>
      {locationFilter && (
        <LocationFilter
          filter={locationFilter}
          onSelect={onSelect(FILTER_NAME.AREA)}
        />
      )}
      {franchiseFilter && (
        <FranchiseFilter
          filter={franchiseFilter}
          onSelect={onSelect(FILTER_NAME.FRANCHISES)}
        />
      )}
      {dealersFilter && (
        <DealersFilter
          filter={dealersFilter}
          onSelect={onSelect(FILTER_NAME.TERMS)}
        />
      )}
    </Fragment>
  );
};

export { FilterManager };
