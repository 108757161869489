import { frontendServerGateway } from 'api/api-utils/commonApiUtils';
import { PAGE } from 'helpers/pages';

/**
 * This Logger Service allows us to pick up client-side errors in kibana
 */
export const loggerService = {
  log: async (context: PAGE, message: any) => {
    try {
      await frontendServerGateway.post('/logger', { route: context, message });
    } catch (err) {
      // fail silently
    }
  },
};
