import { useRouter } from 'next/router';

import type { Breadcrumb } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs.typed';
import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const Breadcrumbs = () => {
  const { back } = useRouter();

  const breadcrumbs: Array<Breadcrumb> = [
    {
      href: '/',
      displayName: 'Home',
    },
    {
      href: '/motor',
      displayName: 'Cars & Motor',
      underline: true,
    },
    {
      displayName: 'Find a Dealer',
    },
  ];

  return (
    <Styled.Breadcrumbs breadcrumbs={breadcrumbs} goBack={back} inverted />
  );
};

export { Breadcrumbs };
