import type { BaseFilterValue } from 'domains/Filter';
import type { AutocompleteFilterProps } from 'components/DealerDirectory/components/DealersFilter/components/AutocompleteFilter/AutocompleteFilter.typed';
import SearchableDropdown, {
  useSearchableDropdown,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';
import { dealerRepository } from 'repositories/Dealer/DealerRepository';
import * as constants from 'components/DealerDirectory/constants';
import { fireToast } from 'helpers/Toasts';
import { loggerService } from 'services/loggerService';
import { PAGE } from 'helpers/pages';

const defaultItem = {
  value: '',
  displayName: constants.DEFAULT_TERMS_TEXT,
};

const AutocompleteFilter = (props: AutocompleteFilterProps) => {
  const { initialData, initialSelected, isOpen, onSelect } = props;
  const {
    searchTerm,
    items,
    isLoading,
    setSearchTerm,
    clearSearchTerm,
    setIsFocus,
    highlight,
  } = useSearchableDropdown({
    initialData: initialData.values,
    initialSearchTerm: initialSelected?.value ?? '',
    initialDisplayText: initialSelected?.displayName ?? '',
    initialExpanded: true,
    threshold: 0,
    defaultItem,
    fetcher: async (terms) => await dealerRepository.autocomplete(terms),
    onError: async (err) => {
      await loggerService.log(PAGE.FIND_A_DEALER, err);
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, Please try again later',
      });
    },
  });

  const filterValue: BaseFilterValue = {
    displayName: searchTerm,
    value: searchTerm,
  };

  const handleSelect = (item: BaseFilterValue) => {
    clearSearchTerm();
    onSelect(item);
  };

  return (
    <SearchableDropdown>
      <SearchableDropdown.Searchbox
        value={searchTerm}
        placeholder={'Type Dealership Name'}
        onChange={(event) => setSearchTerm(event.target.value)}
        onEnter={() => handleSelect(filterValue)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onClear={clearSearchTerm}
        dataTestId="dealer-dropdown-input"
      />
      {isOpen && (
        <SearchableDropdown.List
          loadingCount={items.length}
          isLoading={isLoading}
          isExpanded
          dataTestId="dealer-dropdown-list"
        >
          {items.map((item) => (
            <SearchableDropdown.ListItem
              key={item.value}
              onClick={() => handleSelect(item)}
              dataTestId="dealer-dropdown-listitem"
            >
              {highlight(item.displayName)}
            </SearchableDropdown.ListItem>
          ))}
        </SearchableDropdown.List>
      )}
    </SearchableDropdown>
  );
};

export default AutocompleteFilter;
