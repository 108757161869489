import type { GetServerSidePropsContext } from 'next';
import type { IncomingHttpHeaders } from 'http';
import type { NextSeoProps } from 'next-seo';
import getConfig from 'next/config';
import axios from 'axios';

import type { FilterV2 } from 'domains/Filter';
import type { Dealer, Franchises, Autocomplete } from 'domains/Dealer';
import type { Paging } from 'api/types/dealerApiTypes';
import type {
  ServerResponse,
  QueryParameters,
  DealerDirectoryPageProps,
  DealerDirectorySEOContent,
} from 'components/DealerDirectory/DealerDirectory.typed';
import { ServerSideProps, isServerError } from 'types/server';
import {
  mapDealerFilters,
  mapParametersToQuery,
  mapQueryToPayload,
} from 'components/DealerDirectory/helpers/index.mapper';
import {
  formatSEOContent,
  formatSEOMeta,
} from 'components/DealerDirectory/helpers/index.format';
import { isOk } from 'domains/Result';
import { dealerRepository } from 'repositories/Dealer/DealerRepository';
import { logServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';
import ErrorPage from 'pages/_error';
import { DealerDirectory } from 'components/DealerDirectory/DealerDirectory';
import { DefaultLayout } from 'components/Layouts';
import { updateSiteFocusCookie } from 'helpers/updateSiteFocusCookie';
import { VERTICAL } from 'helpers/verticals';
import { formatServerError } from 'repositories/repository.format';
import MetricService from 'services/MetricService';

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS },
} = getConfig();

const DealerDirectoryPage = (
  props: ServerSideProps<DealerDirectoryPageProps>,
) => {
  if (isServerError(props)) {
    return (
      <ErrorPage
        statusCode={props.code}
        domain={props.domain}
        disableGtmLog={true}
      />
    );
  }
  const { domain = '', ...rest } = props;
  updateSiteFocusCookie(VERTICAL.MOTOR, domain);
  return (
    <DefaultLayout domain={domain} vertical="motor" headerVariant="TRANSPARENT">
      <DealerDirectory {...rest} domain={domain} />
    </DefaultLayout>
  );
};

export default DealerDirectoryPage;

export const getServerSideProps = async (
  context: GetServerSidePropsContext<QueryParameters>,
): ServerResponse => {
  const query = mapParametersToQuery(context.query);
  const headers = context.req.headers;
  const { 'user-agent': userAgent } = headers;
  let franchises: Franchises[] = [];
  let autocomplete: Autocomplete | null = null;
  let dealers: Dealer[] = [];
  let paging: Paging | null = null;
  let seoMeta: NextSeoProps | null = null;
  let seoContent: DealerDirectorySEOContent | null = null;
  const logError = (message: string, ctx?: Object) =>
    console.error(logServerError(PAGE.FIND_A_DEALER, { message, ...ctx }));

  const requestHeaders: IncomingHttpHeaders = {
    ...(userAgent && { 'user-agent': userAgent }),
  };

  const franchisesResponse = await dealerRepository.getFranchises(
    requestHeaders,
  );
  if (isOk(franchisesResponse)) {
    franchises = franchisesResponse.ok;
  } else {
    logError('Error retrieving franchises.', franchisesResponse.error);
    if (axios.isAxiosError(franchisesResponse.error)) {
      return formatServerError(franchisesResponse.error.code);
    } else return formatServerError();
  }

  const payload = mapQueryToPayload(query);

  const dealersResponse = await dealerRepository.search(
    payload,
    requestHeaders,
  );
  if (isOk(dealersResponse)) {
    dealers = dealersResponse.ok.dealers;
    paging = dealersResponse.ok.paging;
    const count = dealersResponse.ok.count;
    seoContent = formatSEOContent(count, query.area);
    seoMeta = formatSEOMeta(CDN_STATIC_ASSETS, query.area);
  } else {
    logError('Error retrieving dealers.', {
      ...dealersResponse.error,
      payload,
    });
    if (axios.isAxiosError(dealersResponse.error)) {
      const metricService = MetricService.get();
      metricService.incrementRequestErrorCount('find-a-dealer', {
        statusCode: dealersResponse.error.response?.status,
      });

      return formatServerError(dealersResponse.error.code);
    } else return formatServerError();
  }
  const autocompleteResponse = await dealerRepository.autocomplete(
    null,
    requestHeaders,
  );
  if (isOk(autocompleteResponse)) {
    autocomplete = autocompleteResponse.ok;
  } else {
    logError(
      'Error retrieving dealers autocomplete.',
      autocompleteResponse.error,
    );
    if (axios.isAxiosError(autocompleteResponse.error)) {
      const metricService = MetricService.get();
      metricService.incrementRequestErrorCount('find-a-dealer', {
        statusCode: autocompleteResponse.error.response?.status,
      });
      return formatServerError(autocompleteResponse.error.code);
    } else return formatServerError();
  }
  const filters: FilterV2[] = mapDealerFilters(franchises, autocomplete);

  return {
    props: {
      seo: { meta: seoMeta, content: seoContent },
      filters,
      dealers,
      paging,
      promoSlot: dealersResponse.ok.promoSlot,
    },
  };
};
