import type { DealerDirectoryProps } from 'components/DealerDirectory/DealerDirectory.typed';
import { Head } from 'components/DealerDirectory/components/Head/Head';
import { Spacer } from 'helpers/genericStyles';
import { Breadcrumbs } from 'components/DealerDirectory/components/Breadcrumbs/Breadcrumbs';
import { Dealers } from 'components/DealerDirectory/components/Dealers/Dealers';
import { FilterManager } from 'components/DealerDirectory/components/FilterManager/FilterManager';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';
import { FeaturedDealer } from 'components/Toolkit/FeaturedDealer/FeaturedDealer';
import { CDN_STATIC_ASSETS } from 'utils';

const DealerDirectory = (props: DealerDirectoryProps) => {
  const { seo, filters, dealers, domain = '', paging, promoSlot } = props;

  return (
    <div>
      <Styled.HeroContainer>
        <Head seo={seo.meta} />
        <Styled.HeroImage />
        <Styled.Overlay>
          <Styled.BreadcrumbsAndHeroContent>
            <div className="row">
              <div className="columns small-12">
                <Breadcrumbs />
                <Spacer marginBottom="M24" />
                <Styled.HeroContent>
                  <Styled.H1 data-testid="heading">
                    Find Dealerships in Ireland
                  </Styled.H1>
                  <Styled.FiltersContainer>
                    <FilterManager filters={filters} />
                  </Styled.FiltersContainer>
                </Styled.HeroContent>
              </div>
            </div>
          </Styled.BreadcrumbsAndHeroContent>
        </Styled.Overlay>
      </Styled.HeroContainer>
      <div className="row">
        <div className="columns small-12">
          <Spacer marginBottom={['M24', 'L40']} />
          <Dealers seoContent={seo.content} dealers={dealers} paging={paging} />
        </div>
      </div>
      {promoSlot && (
        <FeaturedDealer
          ads={promoSlot.ads}
          cdnUrl={CDN_STATIC_ASSETS}
          dealerLogo={promoSlot.largeLogoUrl ?? promoSlot.logoUrl}
          domain={domain}
          heading="Featured Dealer"
          listItems={[
            promoSlot.dealerType,
            `Total Stock: ${promoSlot.totalAdCount} Ads`,
          ]}
          showRoomText="See Showroom"
          showRoomUrl={promoSlot.showroomUrl}
          title={promoSlot.display}
        />
      )}
    </div>
  );
};

export { DealerDirectory };
