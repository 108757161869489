type Days = (typeof daysOfWeek)[number];
type OpeningHourProps = {
  day: Days;
  openTime?: string;
  closeTime?: string;
  appointmentOnly?: boolean;
  appointmentOnlyText?: string;
};

type MappedOpeningHourProps = OpeningHourProps & {
  isToday: boolean;
  isOpen?: boolean;
};

const daysOfWeek = <const>[
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const getToday = () => {
  const today = new Date();
  const todayTimeZone = today.toLocaleString('en-IE', {
    timeZone: 'Europe/Dublin',
  });
  const [hour, min, sec] = todayTimeZone.split(', ')[1].split(':');
  if (hour && min && sec)
    today.setHours(Number(hour), Number(min), Number(sec));
  return today;
};

export function isToday(day: Days) {
  return Boolean(daysOfWeek[getToday().getDay() - 1] === day);
}

export function isCurrentlyOpen(openTime?: string, closeTime?: string) {
  if (!openTime || !closeTime) {
    return false;
  }
  const currentHour = getToday().getHours();
  const currentMinute = getToday().getMinutes();
  const [openingHour, openingMinute] = openTime
    .split(':')
    .map((val) => parseInt(val));
  const [closingHour, closingMinute] = closeTime
    .split(':')
    .map((val) => parseInt(val));

  if (openingHour < currentHour && closingHour > currentHour) {
    return true;
  } else if (openingHour === currentHour) {
    if (openingMinute <= currentMinute) {
      return true;
    }
  } else if (closingHour === currentHour) {
    if (closingMinute >= currentMinute) {
      return true;
    }
  }
  return false;
}

export function isAppointmentOnly(openingHours?: OpeningHourProps[]) {
  if (openingHours) {
    return (
      openingHours.filter(
        ({ openTime, closeTime, appointmentOnly }) =>
          (Boolean(openTime) || Boolean(closeTime)) && !appointmentOnly,
      ).length === 0
    );
  } else {
    return false;
  }
}

export function getOpeningHoursForDay(openingHours?: OpeningHourProps[]) {
  if (openingHours) {
    const currentDay = daysOfWeek[getToday().getDay() - 1];
    const { openTime, closeTime, appointmentOnly, appointmentOnlyText } =
      openingHours.filter((data) => data.day === currentDay)[0];
    if (appointmentOnly) {
      return appointmentOnlyText ?? 'By appointment only';
    } else if (!Boolean(openTime) && !Boolean(closeTime)) {
      return 'Closed';
    } else {
      return `${openTime} - ${closeTime}`;
    }
  } else {
    return '';
  }
}

export function updateOpeningHours(openingHours?: OpeningHourProps[]) {
  if (openingHours) {
    let displayTime = 'Opening hours';
    let mappedOpeningHours: MappedOpeningHourProps[] = openingHours.map(
      (openingHour) => {
        const today = isToday(openingHour.day);
        const currentlyOpen = isCurrentlyOpen(
          openingHour.openTime,
          openingHour.closeTime,
        );
        if (today) {
          if (currentlyOpen !== undefined) {
            if (currentlyOpen) {
              displayTime = 'Open Now';
            } else {
              displayTime = 'CLOSED';
            }
          }
        }
        return {
          ...openingHour,
          isToday: today,
          isOpen: currentlyOpen,
        };
      },
    );
    return { mappedOpeningHours, displayTime };
  } else {
    return { mappedOpeningHours: undefined, displayTime: '' };
  }
}
