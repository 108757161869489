import type { DealerCardProps } from 'components/DealerDirectory/components/DealerCard/DealerCard.typed';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import { CDN_STATIC_ASSETS } from 'utils';
import { Spacer } from 'helpers/genericStyles';
import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';
import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';
import { Tag } from 'components/Toolkit/Tags/Tag';
import TagList from 'components/Toolkit/Tags/TagList';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const TrustedDealer = () => {
  return (
    <Styled.Text>
      <Styled.ShieldCheckmark
        src={`${CDN_STATIC_ASSETS}/images/illustrations/shield-checkmark.svg`}
        alt="trusted dealer"
        width="16"
        height="16"
      />
      Trusted Dealer
    </Styled.Text>
  );
};

const DealerCard = (props: DealerCardProps) => {
  const { dealer } = props;
  const {
    name,
    address,
    slug,
    rating,
    ratingTotal,
    logo,
    logoMedium,
    trusted,
    sections,
  } = dealer;
  return (
    <WithLink href={slug}>
      <Styled.DealerCard
        header={
          <>
            <Styled.Name>{name}</Styled.Name>
            {rating && (
              <>
                <Spacer marginBottom={'S4'} />
                <Styled.Text>
                  <Styled.Gap>
                    <Styled.RatingIcon color="#FF9700" width={16} height={16} />{' '}
                    {rating}
                    /5 ({ratingTotal})
                  </Styled.Gap>
                  {trusted && (
                    <>
                      <Styled.Gap>·</Styled.Gap>
                      <TrustedDealer />
                    </>
                  )}
                </Styled.Text>
              </>
            )}
            {!rating && trusted && (
              <>
                <Spacer marginBottom={'S4'} />
                <TrustedDealer />
              </>
            )}
            <Spacer marginBottom={'S8'} />
            <Styled.Text>
              <Styled.Gap>{address}</Styled.Gap>
              <Styled.Gap>·</Styled.Gap>Stock: {dealer.stock ?? 0}
            </Styled.Text>
            {sections && (
              <>
                <Spacer marginBottom={'S8'} />
                <TagList>
                  {sections?.map((section) => (
                    <Tag
                      key={section}
                      text={section}
                      bgColor={'GREY_LIGHTER'}
                      textColor={'GREY_DARK'}
                      as="li"
                    />
                  ))}
                </TagList>
              </>
            )}
          </>
        }
        image={
          <>
            {logo && logoMedium && (
              <Styled.LogoWrapper
                orientationSmall={ORIENTATION.VERTICAL}
                orientationMedium={ORIENTATION.VERTICAL}
                orientationLarge={ORIENTATION.HORIZONTAL}
                paddingSmall="M16"
                paddingMedium="M16"
                paddingLarge="M24"
                trailingImageLarge
              >
                <Image
                  src={logo}
                  alt={name}
                  imgSrcWebp={logo}
                  widthHorizontalSmall={64}
                  widthHorizontalMedium={64}
                  widthHorizontalLarge={64}
                  orientationSmall={ORIENTATION.HORIZONTAL}
                  orientationMedium={ORIENTATION.HORIZONTAL}
                  orientationLarge={ORIENTATION.HORIZONTAL}
                />
              </Styled.LogoWrapper>
            )}
          </>
        }
        orientationSmall={ORIENTATION.VERTICAL}
        orientationMedium={ORIENTATION.VERTICAL}
        orientationLarge={ORIENTATION.HORIZONTAL}
        paddingSmall="M16"
        paddingMedium="M16"
        paddingLarge="M24"
        trailingImageLarge
      />
    </WithLink>
  );
};

export { DealerCard };
