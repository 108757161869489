import { useState } from 'react';

import type {
  UseSearchableProps,
  HighlightingOptions,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown.typed';
import { isOk } from 'domains/Result';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import useDebounce from 'hooks/useDebounce';

const useSearchableDropdown = <T extends string | Record<string, any>>(
  props: UseSearchableProps<T>,
) => {
  const {
    initialData,
    initialSearchTerm = '',
    initialDisplayText = '',
    threshold = 2,
    autoFocus = false,
    initialExpanded = false,
    defaultItem,
    fetcher,
    onError,
    filterOn,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);
  const [isFocus, setIsFocus] = useState<boolean>(autoFocus);
  const [items, setItems] = useState<T[]>(initialData);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedTerm = useDebounce(searchTerm, 300);

  const reset = () => setItems(initialData);
  const clearSearchTerm = () => setSearchTerm('');

  const search = async () => {
    if (fetcher) {
      setIsLoading(true);
      const data = await fetcher(encodeURIComponent(searchTerm));
      if (isOk(data)) {
        if (defaultItem && searchTerm.length === 0) {
          setItems([defaultItem, ...data.ok]);
        } else setItems(data.ok);
      } else {
        onError && (await onError(data.error));
        setIsLoading(false);
      }
    } else {
      if (filterOn) {
        const filteredItems = initialData.filter((item) =>
          filterOn(item).startsWith(searchTerm),
        );
        setItems(filteredItems);
      }
    }
  };

  const boldIfMatch = (text: string) => {
    const textUpperCase = text.toUpperCase();
    const termUpperCase = searchTerm.toUpperCase();
    if (textUpperCase.includes(termUpperCase)) {
      const startIndex = textUpperCase.indexOf(termUpperCase);
      const endIndex = startIndex + termUpperCase.length;
      const start = text.substring(0, startIndex);
      const middle = text.substring(startIndex, endIndex);
      const end = text.substring(endIndex, text.length);
      return (
        <>
          {start}
          <b>{middle}</b>
          {end}
        </>
      );
    }
    return text;
  };

  const boldIfSelected = (text: string) => {
    if (text === initialDisplayText) {
      return <b>{text}</b>;
    }
    return text;
  };

  const highlight = (
    text: string,
    options: HighlightingOptions = { ifSelected: true, ifMatching: true },
  ) => {
    const { ifSelected, ifMatching } = options;
    if (ifMatching && searchTerm.length >= 1) return boldIfMatch(text);
    if (ifSelected && initialSearchTerm !== '' && searchTerm.length < 1)
      return boldIfSelected(text);
    return text;
  };

  useOnUpdateOnly(() => {
    if (threshold !== undefined && threshold !== 0) {
      if (searchTerm.length > threshold) {
        search();
        !isExpanded && setIsExpanded(true);
      } else {
        reset();
        isExpanded && setIsExpanded(false);
      }
    } else search();
  }, [debouncedTerm, isExpanded]);

  useOnUpdateOnly(() => {
    setIsLoading(false);
  }, [items]);

  return {
    searchTerm,
    items,
    isLoading,
    isFocus,
    isExpanded,
    setIsExpanded,
    setSearchTerm,
    clearSearchTerm,
    setIsFocus,
    highlight,
  };
};

export default useSearchableDropdown;
