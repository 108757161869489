import styled from 'styled-components';

import { withOpacity } from 'helpers/Theme/Theme';
import { ThemeTypes } from 'types/Theme.namespace';
import { media } from 'helpers/breakpoints';
import { CDN_STATIC_ASSETS } from 'utils';
import { StarIc } from '@dsch/dd-icons';
import { Breadcrumbs as TooklitBreadcrumbs } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs';

import { Card } from 'components/ToolkitV2/CardV2/Card';

import {
  ORIENTATION,
  OrientationProps,
} from 'components/ToolkitV2/CardV2/Card.typed';

const HeroContainer = styled.div`
  height: 376px;
  position: relative;
  backgroundimage: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/blurred/small.jpg');
  background-size: cover;
  background-position: right;

  ${media.medium} {
    background-image: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/blurred/medium.jpg');
  }

  ${media.large} {
    height: 320px;
    background-image: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/blurred/large.jpg');
    background-position: center;
  }
`;

const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background-image: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/regular/small.jpg');
  background-size: cover;
  background-position: right;

  ${media.medium} {
    background-image: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/regular/medium.jpg');
  }

  ${media.large} {
    background-image: url('${CDN_STATIC_ASSETS}/images/hero/dealerDirectory/regular/large.jpg');
    background-position: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) =>
    `${withOpacity(theme.colors.GREY_DARKER, 0.56)}`};
`;

const BreadcrumbsAndHeroContent = styled.div`
  padding-top: ${({ theme }) => theme.spacing.L56}};
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Breadcrumbs = styled(TooklitBreadcrumbs)`
  padding: ${({ theme }) => `${theme.spacing.S12} 0 0`};
`;

const H1 = styled.h1`
  ${({ theme }) => theme.fontSize.H24};
  color: ${({ theme }) => theme.colors.WHITE};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  width: 288px;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    width: 480px;
  }

  ${media.large} {
    width: 860px;
    flex-grow: 1;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.M24};
  }
`;

/** FilterManager */

const FilterContainer = styled.div`
  width: 100%;
`;

/** Dealers */
const FilterText = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const DealerCardWrapper = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PaginationContainer = styled.div`
  width: 100%;
`;

/** DealerCard */

type Padding = OrientationProps & {
  trailingImageSmall?: boolean;
  trailingImageMedium?: boolean;
  trailingImageLarge?: boolean;
  paddingSmall: ThemeTypes.Spacing;
  paddingMedium: ThemeTypes.Spacing;
  paddingLarge: ThemeTypes.Spacing;
};

const DealerCard = styled(Card)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.LARGE};
  height: 100%;
  overflow: hidden;
  justify-content: unset;
`;

const LogoWrapper = styled.div<Padding>`
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${({
    paddingSmall,
    trailingImageSmall,
    orientationSmall,
    theme,
  }) => {
    switch (orientationSmall) {
      case ORIENTATION.VERTICAL:
        if (trailingImageSmall)
          return `0 ${theme.spacing[paddingSmall]} ${theme.spacing[paddingSmall]}`;
        return `${theme.spacing[paddingSmall]} ${theme.spacing[paddingSmall]} 0`;
      case ORIENTATION.HORIZONTAL:
      default:
        if (trailingImageSmall)
          return `${theme.spacing[paddingSmall]} ${theme.spacing[paddingSmall]} ${theme.spacing[paddingSmall]} 0`;
        return `${theme.spacing[paddingSmall]} 0 ${theme.spacing[paddingSmall]} ${theme.spacing[paddingSmall]}`;
    }
  }};

  ${media.medium} {
    padding: ${({
      paddingMedium,
      trailingImageMedium,
      orientationMedium,
      theme,
    }) => {
      switch (orientationMedium) {
        case ORIENTATION.VERTICAL:
          if (trailingImageMedium)
            return `0 ${theme.spacing[paddingMedium]} ${theme.spacing[paddingMedium]}`;
          return `${theme.spacing[paddingMedium]} ${theme.spacing[paddingMedium]} 0`;
        case ORIENTATION.HORIZONTAL:
        default:
          if (trailingImageMedium)
            return `${theme.spacing[paddingMedium]} ${theme.spacing[paddingMedium]} ${theme.spacing[paddingMedium]} 0`;
          return `${theme.spacing[paddingMedium]} 0 ${theme.spacing[paddingMedium]} ${theme.spacing[paddingMedium]}`;
      }
    }};
  }

  ${media.large} {
    padding: ${({
      paddingLarge,
      trailingImageLarge,
      orientationLarge,
      theme,
    }) => {
      switch (orientationLarge) {
        case ORIENTATION.VERTICAL:
          if (trailingImageLarge)
            return `0 ${theme.spacing[paddingLarge]} ${theme.spacing[paddingLarge]}`;
          return `${theme.spacing[paddingLarge]} ${theme.spacing[paddingLarge]} 0`;
        case ORIENTATION.HORIZONTAL:
        default:
          if (trailingImageLarge)
            return `${theme.spacing[paddingLarge]} ${theme.spacing[paddingLarge]} ${theme.spacing[paddingLarge]} 0`;
          return `${theme.spacing[paddingLarge]} 0 ${theme.spacing[paddingLarge]} ${theme.spacing[paddingLarge]}`;
      }
    }};
  }
`;

const Name = styled.h3`
  ${({ theme }) => theme.fontSize.H24};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: 700;
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontSize.M14}
  color: ${({ theme }) => theme.colors.GREY_DARK};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const RatingIcon = styled(StarIc)`
  margin-right: 4px;
`;

const ShieldCheckmark = styled.img`
  margin-right: 4px;
`;

const Gap = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export {
  // DealerDirectory
  Overlay,
  HeroContainer,
  HeroImage,
  BreadcrumbsAndHeroContent,
  HeroContent,
  H1,
  Breadcrumbs,
  FiltersContainer,
  // FilterManager
  FilterContainer,
  // Dealers
  FilterText,
  DealerCardWrapper,
  PaginationWrapper,
  PaginationContainer,
  // DealerCard
  DealerCard,
  LogoWrapper,
  Name,
  Text,
  RatingIcon,
  ShieldCheckmark,
  Gap,
};
