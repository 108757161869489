import styled from 'styled-components';
import type { ReactNode } from 'react';

import { media } from 'helpers/breakpoints';

type TDesktopProps = {
  children: ReactNode;
  display?: string;
  className?: string;
};
type TDesktop = (props: TDesktopProps) => JSX.Element;

type TContainer = { display: string };
const Container = styled.div<TContainer>`
  display: none;

  ${media.medium} {
    display: ${({ display }) => display};
  }
`;

const Desktop: TDesktop = ({ children, display = 'block', className }) => {
  return (
    <Container display={display} className={className}>
      {children}
    </Container>
  );
};

export { Desktop };
