import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.M24} 0;
`;

const Heading = styled.h1`
  ${({ theme }) => theme.fontSize.H32}
`;

const Body = styled.p`
  ${({ theme }) => theme.fontSize.M16}
`;

export { Container, Heading, Body };
