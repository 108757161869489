import { SearchOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import type { SearchboxProps } from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.styled';

const Searchbox = (props: SearchboxProps) => {
  const { className, value, autoFocus, dataTestId, onClear, onEnter, ...rest } =
    props;
  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') onEnter && onEnter();
  };
  return (
    <Styled.Wrapper className={className}>
      <Styled.Container>
        <SearchOutlineIc height={24} width={24} />
        <Styled.Input
          value={value}
          autoFocus={autoFocus}
          autoComplete="off"
          type="search"
          onKeyPress={onKeyPress}
          data-testid={dataTestId}
          {...rest}
        />
        {value && value.toString().length > 0 && (
          <Styled.Button onClick={onClear} data-testid="searchbox-clear">
            <CloseOutlineIc width={12} height={12} />
          </Styled.Button>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Searchbox;
