import { VehicleLookUpData } from 'components/PageWrappers/AdDetails/components/DealerEnquiryModal/DealerEnquiryModal.config';
import { IMetaInfoItem } from 'components/Toolkit/PublishFilters/types/index';
import type { Currency, MILEAGE_UNIT, PlotValue } from 'types';
import { DASHBOARD_PAGE_TYPES, CURRENCIES } from 'types';
import { TDatePickerValues } from 'components/Toolkit/PublishFilters/types/index';
import type { SubFilterValue } from 'components/AdForm/Panels/PublishFilters/types/index';
import {
  USER_MENU,
  USER_MENU_DISPLAY_NAMES,
} from 'components/Layouts/components/UserMenu';

interface PriceRange {
  min: number;
  max: number;
}

export const formatNumber = (number?: number) => {
  if (number !== undefined) {
    try {
      return new Intl.NumberFormat('en-IE').format(number);
    } catch (_err) {
      return number;
    }
  } else {
    undefined;
  }
};

export const formatValuesAsString = (values: string[]): string | undefined => {
  const count = values.length;
  if (count && count > 1) {
    return `${values[0]} (+${values.length - 1})`;
  } else if (count) {
    return values[0];
  }
  return;
};

export const formatSellerType = (
  isTrader: boolean,
  verification?: { email?: boolean; phone?: boolean; bank?: boolean },
  sellerType?: string,
) => {
  if (verification) {
    const { email, phone, bank } = verification;
    if (email && phone && bank) {
      if (isTrader) {
        return 'Verified Trader';
      } else if (sellerType === 'PRIVATE') {
        return 'Verified Private Seller';
      } else {
        return undefined;
      }
    } else {
      if (isTrader) {
        return 'Trader';
      } else if (sellerType === 'PRIVATE') {
        return 'Private Seller';
      } else {
        return undefined;
      }
    }
  }
};

export const formatVerification = (verification: {
  email?: boolean;
  phone?: boolean;
  bank?: boolean;
}) => {
  const { email, phone, bank: identity } = verification;
  if (email !== undefined && phone !== undefined && identity !== undefined) {
    return { email, phone, identity };
  } else {
    return undefined;
  }
};

export const formatRegistrationDate = (registrationDate: string) =>
  registrationDate.substring(0, 4);

export const formatAddress = (
  address?: string,
  enhancedAddress?: {
    streetAddress: string | null;
    addressLocality: string | null;
    addressRegion: string | null;
    postalCode: string | null;
  },
  isShort: boolean = false,
) => {
  const addressHolder = [];
  if (enhancedAddress !== undefined) {
    const { postalCode, ...enhanced } = enhancedAddress;
    for (const [_key, value] of Object.entries(enhanced)) {
      if (value) {
        if (value.charAt(value.length - 1) === ',') {
          addressHolder.push(value.slice(0, -1));
        } else {
          addressHolder.push(value);
        }
      }
    }
  }
  if (addressHolder.length !== 0) {
    return isShort
      ? addressHolder.splice(-2).join(', ')
      : addressHolder.join(', ');
  } else {
    return address
      ? address
          .split(', ')
          .slice(isShort ? -2 : -3)
          .join(', ')
      : undefined;
  }
};

export const thousandSeparated = function (val: number | string) {
  return val
    .toString()
    .replace(/,/, '')
    .replace(/\B(?=(\d{3})+(?!\d))/, ',');
};

type DisplayWithValue = {
  label: string;
  value: string;
};

export const generateYearRange = () => {
  let yearRange: DisplayWithValue[] = [];
  const currentDate = new Date();
  let startYear = currentDate.getFullYear();
  if (currentDate.getMonth() >= 7) {
    // August
    startYear++;
  }

  for (let i = 0; i <= 20; i++) {
    yearRange.push({
      label: (startYear - i).toString(),
      value: (startYear - i).toString(),
    });
  }
  return yearRange;
};

export const constructUrlParams = (
  ...args: {
    [key: string]: string | string[] | undefined;
  }[]
) => {
  const params = args[0];
  Object.keys(params).forEach((key) =>
    params[key] === undefined || params[key] === '' ? delete params[key] : {},
  );
  const paramsURL = new URLSearchParams(params as Record<string, string>);
  Object.keys(params).map((key) => {
    if (Array.isArray(params[key]) && key !== 'model') {
      (params[key] as string[]).map((val, index) => {
        if (index === 0) {
          paramsURL.set(key, val);
        } else {
          paramsURL.append(key, val);
        }
      });
    }
  });
  return decodeURI(paramsURL.toString());
};

export const formatPublishFilterValues = (
  publishFilterValues?: {
    id?: number;
    displayName: string;
    valueType: string;
    value: string;
  }[],
  lowercase = true,
) => {
  return (
    publishFilterValues &&
    publishFilterValues.map((value: any) => {
      if (lowercase) {
        return {
          value: value.value,
          label: value.displayName?.toLowerCase(),
        };
      }
      return {
        value: value.value,
        label: value.displayName,
      };
    })
  );
};

export const formatPrice = (value: number) => {
  if ((value / 100) % 1 !== 0) {
    return (value / 100).toFixed(2);
  }
  return value / 100;
};

export const roundToNearestIncrement = (value: number, increment: number) => {
  return Math.round(value / increment) * increment;
};

export const formatCurrency = (currency: Currency) => {
  return currency === 'EUR' ? '€' : '£';
};

export const formatPriceWithCurrency = (value: number, currency: Currency) => {
  return `${formatCurrency(currency)}${formatNumber(value)}`;
};

export function alertTextFinder(alert?: string) {
  switch (alert) {
    case 'DAILY':
      return 'daily_alerts';
    case 'INSTANT':
      return 'instant_alerts';
    case 'OFF':
      return 'no_alerts';
    default:
      return undefined;
  }
}

export function displayDashboardSectionName(name?: string) {
  switch (name) {
    case DASHBOARD_PAGE_TYPES.LISTINGS:
      return {
        name: USER_MENU.MY_ADS,
        displayName: USER_MENU_DISPLAY_NAMES.MY_ADS,
      };
    case DASHBOARD_PAGE_TYPES.SAVED:
      return {
        name: USER_MENU.SAVED,
        displayName: USER_MENU_DISPLAY_NAMES.SAVED,
      };
    case DASHBOARD_PAGE_TYPES.SEARCHES:
      return {
        name: USER_MENU.SEARCHES,
        displayName: USER_MENU_DISPLAY_NAMES.SEARCHES,
      };
    case DASHBOARD_PAGE_TYPES.PAYMENTS:
      return {
        name: USER_MENU.PAYMENTS,
        displayName: USER_MENU_DISPLAY_NAMES.PAYMENTS,
      };
    case DASHBOARD_PAGE_TYPES.BROWSING_HISTORY:
      return {
        name: USER_MENU.BROWSING_HISTORY,
        displayName: USER_MENU_DISPLAY_NAMES.BROWSING_HISTORY,
      };
    case DASHBOARD_PAGE_TYPES.HISTORY_CHECKS:
      return {
        name: USER_MENU.HISTORY_CHECKS,
        displayName: USER_MENU_DISPLAY_NAMES.HISTORY_CHECKS,
      };
    case DASHBOARD_PAGE_TYPES.MESSAGES:
      return {
        name: USER_MENU.MESSAGES,
        displayName: USER_MENU_DISPLAY_NAMES.MESSAGES,
      };
    default:
      return {
        name: USER_MENU.MY_DONEDEAL,
        displayName: USER_MENU_DISPLAY_NAMES.MY_DONEDEAL,
      };
  }
}

export const carValuationPriceFormatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: CURRENCIES.EUR,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const formatPriceRange = (priceRange: PriceRange) => {
  return `${carValuationPriceFormatter.format(
    priceRange.min,
  )} - ${carValuationPriceFormatter.format(priceRange.max)}`;
};

const months: { [key: string]: string } = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const formatSellerAnalyticsGraphLabels = (data: PlotValue[]) =>
  data.map((item) => {
    if (item.endDate) {
      const [_year, month, day] = item.date.split('-');
      const [_endYear, endMonth, endDay] = item.endDate.split('-');
      if (month === endMonth) {
        return `${day.replace(/^0+/, '')} - ${endDay.replace(/^0+/, '')} ${
          months[endMonth]
        }`;
      } else {
        return `${day.replace(/^0+/, '')} ${months[month]} - ${endDay.replace(
          /^0+/,
          '',
        )} ${months[endMonth]}`;
      }
    }
    const [_year, month, day] = item.date.split('-');
    return `${day.replace(/^0+/, '')} ${months[month]}`;
  });

export const formatSellerAnalyticsGraphValues = (data: PlotValue[]) =>
  data.map((item: PlotValue) => {
    return item.value;
  });
/**
 *
 * @param price The price in cents (required)
 * @param sterlingPrice The sterling price in cents (optional)
 * @param currency The currency (optional)
 * @returns a formatted price in the correct currency
 */
export const formatPriceInCentsWithCurrency = (
  price: number,
  sterlingPrice?: number,
  currency?: Currency,
) => {
  if (currency === 'GBP' && sterlingPrice) {
    return priceFormatter(sterlingPrice, 'GBP');
  }
  return priceFormatter(price, currency);
};

/**
 *
 * @param totalInCents The total price in cents (required)
 * @param currency The currency (defaults to 'EUR')
 * @returns a formatted string with currency and corresponding price
 */
export const priceFormatter = (
  totalInCents: number,
  currency: Currency = 'EUR',
) => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(totalInCents / 100);
};

export function formatNCT(unformattedNCT: string) {
  const [year, month, _day] = unformattedNCT.split('-');
  return `NCT ${month}/${year.slice(-2)}`;
}

export function formatTradeInValuesForDisplay(
  data: VehicleLookUpData | undefined,
) {
  if (data === undefined) {
    return undefined;
  }
  const {
    make,
    model,
    year,
    engine,
    transmission,
    bodyType,
    fuelType,
    nct,
    owners,
  } = data;

  return {
    make: make ?? '',
    model: model ?? '',
    year: year ?? '',
    engine: engine ? `${(parseInt(engine) / 1000).toFixed(1)} ${fuelType}` : '',
    transmission: transmission ?? '',
    bodyType: bodyType ?? '',
    nct: nct ? formatNCT(nct) : '',
    owners: `${owners} Owner${owners !== '1' ? 's' : ''}`,
  };
}

export const findAttributeFromMetaData = (
  name: string,
  attributes: IMetaInfoItem[],
) => {
  return attributes.find((attribute) => attribute.name === name);
};

export const generateMetaData = (
  data: IMetaInfoItem[],
  mileage: string,
  registration: string,
  mileageUnit: MILEAGE_UNIT.KILOMETRES | MILEAGE_UNIT.MILES,
) => {
  return {
    make: findAttributeFromMetaData('make', data)?.displayValue || '',
    model: findAttributeFromMetaData('model', data)?.displayValue || '',
    year: findAttributeFromMetaData('year', data)?.displayValue || '',
    trim: findAttributeFromMetaData('trim', data)?.displayValue || '',
    bodyType: findAttributeFromMetaData('bodyType', data)?.displayValue || '',
    fuelType: findAttributeFromMetaData('fuelType', data)?.displayValue || '',
    transmission:
      findAttributeFromMetaData('transmission', data)?.displayValue || '',
    registration,
    mileage,
    mileageUnit,
  };
};

export const convertMilesToKm = (mileage: number) => {
  return Math.floor(mileage * 1.60934).toString();
};

export const formatUrlParamsAndQueries = (
  value: string | string[] | undefined,
) => {
  return value ? (Array.isArray(value) ? value[0] : value) : null;
};

// Need to hard coded dynamic year for nct due to publish filters refactor,

export const generateYearsForNCT = () => {
  const currentYear = new Date().getFullYear();
  const years: TDatePickerValues[] = [
    {
      id: 0,
      valueType: null,
      value: '',
      displayName: '',
    },
  ];
  for (let index = currentYear - 3; index <= currentYear + 4; index++) {
    years.push({
      id: 0,
      valueType: null,
      value: index.toString(),
      displayName: index.toString(),
    });
  }
  return years;
};

export const generateMonthsForNCT = () => {
  const months = [
    {
      id: 0,
      valueType: null,
      value: '',
      displayName: '',
    },
  ];

  for (let index = 1; index <= 12; index++) {
    const value = index > 9 ? index : `0${index}`;
    const month = {
      id: 0,
      valueType: null,
      value: value.toString(),
      displayName: value.toString(),
    };
    months.push(month);
  }

  return months;
};

export const datePickerValues = {
  id: 221,
  valueType: 'SearchFilterCalendarValue',
  years: generateYearsForNCT(),
  months: generateMonthsForNCT(),
};

export function mapFilterValuesForDropdown(filterValues: SubFilterValue[]) {
  return filterValues.map((filterValue: SubFilterValue) => {
    return {
      id: 0,
      valueType: filterValue.valueType,
      value: filterValue.value,
      displayName: filterValue.displayName,
      name: filterValue.displayName,
    };
  });
}
export const isEmptyObject = <T extends {}>(obj: T) => {
  return Boolean(!obj || Object.keys(obj).length === 0);
};

export const boldMatchingText = (text: string, matchingInput: string) => {
  const strRegExp = new RegExp(matchingInput, 'gi');
  const result = text.replace(strRegExp, function (match) {
    return `<b>${match}</b>`;
  });
  return result;
};

export const camelToSnakeCase = (text: string) =>
  text.replace(/(?=[A-Z])/g, '_').toLowerCase();
