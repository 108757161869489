import { CURRENCIES } from 'types';

export interface Filter {
  name: string;
  values: string[];
}

export const FILTER_TYPE = {
  SIMPLE: 'SIMPLE',
  GROUP: 'GROUP',
  BASE: 'BASE',
  RANGE: 'RANGE',
  MAKE_MODEL: 'MAKE_MODEL',
} as const;

export type FilterVariants = keyof typeof FILTER_TYPE;

type SimpleFilter = string;

export type BaseFilterValue = {
  displayName: string;
  value: string;
};

type FilterGroup = {
  groupDisplayName: string;
  values: BaseFilterValue[];
};

type FilterValues<T extends FilterVariants> = T extends 'SIMPLE'
  ? SimpleFilter
  : T extends 'BASE'
  ? BaseFilterValue
  : T extends 'GROUP'
  ? FilterGroup
  : T extends 'RANGE'
  ? Range
  : T extends 'MAKE_MODEL'
  ? MakeModel
  : never;

export interface FilterV2<T extends FilterVariants = FilterVariants> {
  variant: T;
  name: string;
  values: FilterValues<T>[];
}

export interface Range {
  name: string;
  to?: string;
  from?: string;
  currency?: CURRENCIES;
  typePrice?: 'price' | 'pricePerMonth';
}
export interface MakeModel {
  id: string;
  makeValue: string;
  modelValue: string[];
  isEditable: boolean;
  isVisible: boolean;
}

/**
 * This function will will return a filter with correct type-inference based on the filter variant
 * @param variant the type of filter
 * @param name the filter name
 * @param values the list of filter values
 * @returns the filter data
 */
const createFilter = <T extends FilterVariants>(
  variant: T,
  name: string,
  values: FilterValues<T>[],
): FilterV2<T> => {
  return { variant, name, values };
};

const isSimple = (filter: FilterV2): filter is FilterV2<'SIMPLE'> => {
  return filter.variant === 'SIMPLE';
};

const isBase = (filter: FilterV2): filter is FilterV2<'BASE'> => {
  return filter.variant === 'BASE';
};

const isGroup = (filter: FilterV2): filter is FilterV2<'GROUP'> => {
  return filter.variant === 'GROUP';
};

const getGroupValues = (filter: FilterV2<'GROUP'>): BaseFilterValue[] => {
  return filter.values.reduce((acc, { values }) => [...acc, ...values], []);
};

const getFilterByValue = (
  filterValues: BaseFilterValue[],
  value: string,
): BaseFilterValue | null => {
  return (
    filterValues.find((filterValue) => filterValue.value === value) ?? null
  );
};

const getRange = (ranges: Range[], name: string) =>
  ranges.find((range: Range) => range.name === name);

const getFilter = (filters: Filter[], name: string) =>
  filters.find((filter: Filter) => filter.name === name);

const getFilterV2 = <T extends FilterVariants>(
  filters: FilterV2[],
  name: string,
): FilterV2<T> | null =>
  (filters.find(
    (filter: FilterV2<T>) => filter.name === name,
  ) as FilterV2<T>) ?? null;

const getFilterValue = (filters: Filter[], name: string) => {
  const filter = getFilter(filters, name);
  return filter && filter.values.length > 0 ? filter.values[0] : '';
};

const getRangeValue = (ranges: Range[], name: string) => {
  const range = getRange(ranges, name);
  return range ? range : undefined;
};

const getTotalFilters = (
  filters: Filter[],
  ranges: Range[],
  makeModels: MakeModel[],
) => {
  let count = 0;
  for (const filter of filters) {
    if (filter.values.length > 1) {
      count += 1;
    } else if (filter.values.length === 1 && filter.values[0] !== '') {
      count += 1;
    }
  }
  for (const range of ranges) {
    if (typeof range.to !== 'undefined' || typeof range.from !== 'undefined') {
      count += 1;
    }
  }
  const makeModelCount = makeModels.filter(
    (makeModelGroup) =>
      makeModelGroup.makeValue.length > 0 &&
      makeModelGroup.makeValue !== 'All Makes' &&
      makeModelGroup.makeValue !== '',
  ).length;

  count += makeModelCount;
  return count;
};

const getMakeValues = (makeModels: MakeModel[]) =>
  makeModels.reduce<string[]>((group, item) => {
    !!item.makeValue && group.push(item.makeValue);
    return group;
  }, []);

const getModelValues = (makeModels: MakeModel[]) => {
  return makeModels.reduce<string[]>((group, item) => {
    if (!!item.modelValue.length) {
      return [...group, ...item.modelValue];
    }
    return group;
  }, []);
};

const getFuelTypes = (filters: Filter[]): string[] | undefined => {
  const fuelFilter = getFilter(filters, 'fuelType');
  return fuelFilter?.values.length ? fuelFilter?.values : undefined;
};

const getColours = (filters: Filter[]): string[] | undefined => {
  const colourFilter = getFilter(filters, 'colour');
  return colourFilter?.values.length ? colourFilter?.values : undefined;
};

const getBodyTypes = (filters: Filter[]): string[] | undefined => {
  const bodyTypeFilter = getFilter(filters, 'bodyType');
  return bodyTypeFilter?.values.length ? bodyTypeFilter.values : undefined;
};

const getTransmissions = (filters: Filter[]): string[] | undefined => {
  const transmissionFilter = getFilter(filters, 'transmission');
  return transmissionFilter?.values.length
    ? transmissionFilter.values
    : undefined;
};

const getAreas = (filters: Filter[]): string[] =>
  getFilter(filters, 'area')?.values || [];

const getMakes = (filters: Filter[], makeModels: MakeModel[]): string[] => {
  const makes = getMakeValues(makeModels);
  if (makes.length > 0) {
    return makes;
  } else {
    return getFilter(filters, 'make')?.values || [];
  }
};

const getModels = (makeModels: MakeModel[]): string[] => {
  const models = getModelValues(makeModels);
  if (models) {
    return models;
  }
  return [];
};

const getWanted = (filters: Filter[]): boolean =>
  getFilter(filters, 'adType')?.values[0] === 'wanted';

const getYears = (ranges: Range[]): string[] | undefined => {
  const yearRange = getRange(ranges, 'year');
  if (!!yearRange?.to && yearRange.to === yearRange?.from) {
    return [yearRange?.to];
  }
  return;
};

const isPrivateSellerAd = (filters: Filter[]) =>
  filters
    .find((filter) => filter.name === 'sellerType')
    ?.values.includes('private');

export {
  //Filters
  getFilter,
  getFilterValue,
  isPrivateSellerAd,
  getWanted,
  getAreas,
  getBodyTypes,
  getColours,
  getFuelTypes,
  getTransmissions,
  //FiltersV2
  createFilter,
  getFilterV2,
  getGroupValues,
  getFilterByValue,
  //Ranges
  getRange,
  getRangeValue,
  getYears,
  //Make Models
  getMakeValues,
  getModelValues,
  getModels,
  getMakes,
  //General
  getTotalFilters,
  //assertions
  isSimple,
  isBase,
  isGroup,
};
