import type { DealersFilterProps } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.typed';
import { useDealersFilter } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.hook';
import { Mobile } from 'components/Support/Mobile/Mobile';
import { Desktop } from 'components/Support/Desktop/Desktop';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import AutocompleteFilter from 'components/DealerDirectory/components/DealersFilter/components/AutocompleteFilter/AutocompleteFilter';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const DealersFilter = (props: DealersFilterProps) => {
  const {
    data,
    isMobileOpen,
    isOpen,
    handleSelect,
    open,
    openMobile,
    closeMobile,
    ref,
  } = useDealersFilter(props);
  const { name, placeholder, selected } = data;

  return (
    <Styled.FilterContainer>
      <Mobile>
        <InputButton
          heightVariant="LARGE"
          isActive={isMobileOpen}
          value={selected.displayName}
          placeholder={placeholder}
          onClick={openMobile}
          dataTestId="dealer-dropdown-button-mobile"
        />
        <BottomSheet
          headerText={selected.displayName}
          onApply={closeMobile}
          askToClose={closeMobile}
          isOpen={isMobileOpen}
          optionSelected={Boolean(selected.value)}
          resetScrollPosition={isMobileOpen}
        >
          <AutocompleteFilter
            initialData={data}
            initialSelected={selected}
            onSelect={handleSelect('mobile')}
            isOpen={isMobileOpen}
          />
        </BottomSheet>
      </Mobile>
      <Desktop>
        <div ref={ref}>
          <Dropdown
            id={name}
            expanded={isOpen}
            setExpanded={open}
            placeholder={placeholder}
            displayedValue={selected.displayName}
            heightVariant="LARGE"
            data-role="listbox"
            maxHeight={'376px'}
            dataTestId="dealer-dropdown-button-desktop"
          >
            <AutocompleteFilter
              initialData={data}
              initialSelected={selected}
              onSelect={handleSelect('desktop')}
              isOpen={isOpen}
            />
          </Dropdown>
        </div>
      </Desktop>
    </Styled.FilterContainer>
  );
};

export { DealersFilter };
