import { useRouter } from 'next/router';

function useCampaignURLHandler() {
  const router = useRouter();

  function handleCampaignAdRouterPush(path: string) {
    router.push(path);
  }

  return {
    handleCampaignAdRouterPush,
  };
}

export { useCampaignURLHandler };
