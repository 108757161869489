import NextLink from 'next/link';

import { Link } from 'components/Toolkit/Button/Link';

import { Spacer } from 'helpers/genericStyles';

import * as Styled from 'components/DealerDirectory/components/NoResults/NoResults.styled';

const NoResults = () => {
  return (
    <Styled.Container>
      <Styled.Heading>
        Sorry, we couldn't find what you were looking for
      </Styled.Heading>
      <Spacer marginBottom={['M16', 'S8']} />
      <Styled.Body>
        Try broadening your filters or searching&nbsp;
        <Link href="/find-a-dealer" NextLink={NextLink} inline>
          All Dealerships
        </Link>
      </Styled.Body>
    </Styled.Container>
  );
};

export { NoResults };
