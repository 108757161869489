import { useState, useRef } from 'react';
import { useRouter } from 'next/router';

import type { LocationFilterProps } from 'components/DealerDirectory/components/LocationFilter/LocationFilter.typed';
import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import { useClickOutside } from 'hooks/useClickOutside';
import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { formatSelectedLocation } from 'components/DealerDirectory/components/LocationFilter/LocationFilter.format';
import { mapParametersToQuery } from 'components/DealerDirectory/helpers/index.mapper';

type Location = LocationFilterProps['filter'] & {
  placeholder: string;
  selected: DealerDirectoryFilterSelected;
};

const useLocationFilter = (props: LocationFilterProps) => {
  const { filter, onSelect } = props;
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const query = mapParametersToQuery(router.query);
  const selectedLocation = query.area;

  const getLocation = (): Location => ({
    ...filter,
    placeholder: filter.values[0].displayName,
    selected: formatSelectedLocation(selectedLocation),
  });

  const handleSelect = (screen: 'mobile' | 'desktop') => (item: ListItem) => {
    switch (screen) {
      case 'mobile':
        closeMobile();
        break;
      case 'desktop':
        close();
    }
    onSelect(item);
  };

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const openMobile = () => setIsMobileOpen(true);
  const closeMobile = () => setIsMobileOpen(false);

  useClickOutside({ active: isOpen, ref, onClickOutside: close });

  return {
    data: getLocation(),
    isOpen,
    isMobileOpen,
    ref,
    handleSelect,
    open,
    close,
    openMobile,
    closeMobile,
  };
};

export { useLocationFilter };
