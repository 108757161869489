import type { GetServerSidePropsResult } from 'next';
import type { NextSeoProps } from 'next-seo';

import type { FilterV2 } from 'domains/Filter';
import type { Dealer } from 'domains/Dealer';
import type { Paging } from 'api/types/dealerApiTypes';
import type { ServerSideProps } from 'types/server';
import { PromoSlot } from 'types';

export type ServerResponse = Promise<
  GetServerSidePropsResult<ServerSideProps<DealerDirectoryProps>>
>;
export type QueryParameters = {
  terms: string;
  area: string;
  franchises: string;
  from: string;
};

export const QUERY_NAME = {
  AREA: 'counties',
  FRANCHISES: 'franchises',
  TERMS: 'terms',
  FROM: 'from',
} as const;
export type DealerDirectoryQueryNamesKeys = keyof typeof QUERY_NAME;
export type DealerDirectoryQueryNames =
  (typeof QUERY_NAME)[keyof typeof QUERY_NAME];

export const FILTER_NAME = {
  AREA: 'area',
  FRANCHISES: 'franchises',
  TERMS: 'terms',
  FROM: 'from',
} as const;
export type DealerDirectoryFilterNamesKeys = keyof typeof FILTER_NAME;
export type DealerDirectoryFilterNames =
  (typeof FILTER_NAME)[keyof typeof FILTER_NAME];

export type DealerDirectoryFilterSelected = {
  displayName: string;
  value: string | null;
};

export type DealerDirectorySEOContent = {
  countInLocationText: string;
};

export type DealerDirectorySEO = {
  meta: NextSeoProps;
  content: DealerDirectorySEOContent;
};

export type DealerDirectoryPageProps = {
  seo: DealerDirectorySEO;
  filters: Array<FilterV2>;
  dealers: Array<Dealer>;
  paging: Paging;
  domain?: string;
  /**
   * Optionally available or not based on server criteria.
   */
  promoSlot: PromoSlot | null;
};

export type DealerDirectoryProps = {
  seo: DealerDirectorySEO;
  filters: Array<FilterV2>;
  dealers: Array<Dealer>;
  paging: Paging;
  domain?: string;
  /**
   * Optionally available or not based on server criteria.
   */
  promoSlot: PromoSlot | null;
};
